import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const deletePracticePlayers = async (practicePlayersId) => {
  const url = `${BASE_URL}/practice-players/${practicePlayersId}`

  return axios.delete(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default deletePracticePlayers
