import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getOrgQuote = async (data) => {
  const { orgId, addonType, seats } = data

  const url = orgId
    ? `${BASE_URL}/billing/subscriptions/get-quote/${addonType}?seats=${seats}&orgId=${orgId}`
    : `${BASE_URL}/billing/subscriptions/get-quote/${addonType}?seats=${seats}`
  // Make the request with orgId as a query parameter
  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getOrgQuote
