import React from 'react'
import { Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useRedirect, useTranslate } from 'react-admin'
import UseQueryParams from '../../../hooks/useQueryParams'
import youtubeUnlink from '../../../Apis/youtube/youtubeUnlink'
import AppIntegration from '../AppIntegration/AppIntegration'
import youtubeAuthorizeScreen from '../../../Apis/youtube/youtubeAuthorizeScreen'
import QueryKeys from '../../../utils/constants/queryKeys'
import googleAuthorizationCallback from '../../../Apis/youtube/googleAuthorizationCallback'
import localStorageIds from '../../../utils/constants/localStorageIds'
import useHistoryPush from '../../../hooks/useHistoryPush'
import localStorageHelper from '../../../utils/helpers/localStorageHelper'
import CardWrapperMoreIcon from '../../../components/layout/CardWrapperMoreIcon/CardWrapperMoreIcon'
import appsPageStyles from './appsPage.styles'
import YoutubeConfigurationForm from '../YoutubeConfigurationForm/YoutubeConfigurationForm'
import youtubeLogoImage from '../../../assets/images/youtubeLogoImage.png'
import GoogleSignInIcon from '../../../assets/icons/GoogleSignInIcon'

const AppsPage = () => {
  const orgLocalStorageHelper = localStorageHelper(localStorageIds.SELECTED_ORG)
  const redirectToExternalUrl = useRedirect()
  const { redirect } = useHistoryPush()
  const queryParams = UseQueryParams()
  const queryClient = useQueryClient()
  const { code, org: orgId } = queryParams
  const translate = useTranslate()
  const useStyles = makeStyles(appsPageStyles)
  const classes = useStyles()

  const youTubeChannelName = orgLocalStorageHelper.localStorageValue?.youTubeChannelName || ''

  const invalidateQueries = () => {
    queryClient.invalidateQueries(QueryKeys.GET_ORGS_AFTER_LOGIN).then(() => {
      queryClient.invalidateQueries(QueryKeys.GET_TEAMS_AFTER_LOGIN).then(() => {})
    })
  }

  const isInvalidating = () => {
    return (
      queryClient.isFetching(QueryKeys.GET_ORGS) +
        queryClient.isFetching(QueryKeys.GET_ORGS_AFTER_LOGIN) +
        queryClient.isFetching(QueryKeys.GET_TEAMS_AFTER_LOGIN) >
      0
    )
  }

  const YoutubeAuthorizeScreen = useMutation(() => youtubeAuthorizeScreen(), {
    onSuccess: (res) => {
      redirectToExternalUrl(res.data)
    },
  })

  const youtubeUnlinkMutation = useMutation(() => youtubeUnlink({ orgId }), {
    onSuccess: () => {
      invalidateQueries()
    },
  })

  const googleCallback = useQuery(
    [QueryKeys.GOOGLE_AUTHORIZATION_CALLBACK],
    () => googleAuthorizationCallback({ code, orgId }),
    {
      enabled: Boolean(code && orgId),
      retry: false,
      onSuccess: () => {
        redirect('/apps', [], ['code', 'scope'])
        invalidateQueries()
      },
      onError: () => {
        redirect('/apps', [], ['code', 'scope'])
      },
    },
  )

  return (
    <CardWrapperMoreIcon>
      <Card className={classes.card}>
        <CardHeader
          title={
            <Typography className={classes.cardTitle} variant='h5'>
              {translate('ra.text.apps')}
            </Typography>
          }
          subheader={
            <Typography className={classes.cardSubtitle}>
              {translate('ra.text.manageIntegrations')}
            </Typography>
          }
        />
        <CardContent>
          <AppIntegration
            linkMutation={YoutubeAuthorizeScreen}
            unLinkMutation={youtubeUnlinkMutation}
            icon={<img className={classes.youtubeImage} src={youtubeLogoImage} alt='img' />}
            text={translate('ra.text.youtubeIntegrationText')}
            infoText={
              <div
                dangerouslySetInnerHTML={{ __html: translate('ra.text.youtubeIntegrationInfo') }}
              />
            }
            linkButton={<GoogleSignInIcon />}
            isLoading={
              youtubeUnlinkMutation.isLoading ||
              googleCallback.isLoading ||
              YoutubeAuthorizeScreen.isLoading ||
              isInvalidating()
            }
            linkedAccountName={youTubeChannelName}
            configurationForm={<YoutubeConfigurationForm />}
          />
        </CardContent>
      </Card>
    </CardWrapperMoreIcon>
  )
}

export default AppsPage
