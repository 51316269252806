import React from 'react'
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'
import perQuarterTableStyles from './perQuarterTable.styles'

const PerQuarterTable = ({ teams, sum }) => {
  const useStyles = makeStyles(perQuarterTableStyles)
  const translate = useTranslate()
  const classes = useStyles()

  const findUniqueMax = (array) => {
    const max = Math.max(...array)
    const count = array.filter((value) => value === max).length
    return count === 1 ? max : null
  }

  const periods = teams?.reduce((acc, team) => {
    Object.keys(team.periodScores).forEach((period) => {
      if (!acc.includes(period)) {
        acc.push(period)
      }
    })
    return acc
  }, [])

  // Compute cumulative scores if sum is enabled
  const cumulativeScores = teams.map((team) => {
    let cumulativeSum = 0
    return {
      ...team,
      periodScores: periods.reduce((acc, period) => {
        cumulativeSum += team.periodScores[period] || 0
        acc[period] = sum ? cumulativeSum : team.periodScores[period] || 0
        return acc
      }, {}),
    }
  })

  const maxValues = periods.reduce((acc, period) => {
    acc[period] = findUniqueMax(cumulativeScores.map((team) => team.periodScores[period] || 0))
    return acc
  }, {})

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell className={classes.tableHeader}>{translate('ra.text.team')}</TableCell>
            {periods?.map((period) => (
              <TableCell key={period} className={classes.tableHeader}>
                {translate('ra.text.period')} {period}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {cumulativeScores.map((team) => (
            <TableRow key={team.teamId}>
              <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{team.name}</TableCell>
              {periods.map((period) => {
                const displayedScore = team.periodScores[period] || 0
                return (
                  <TableCell key={`${team.teamId}-${period}`} className={classes.tableCell}>
                    <span
                      className={classes.spanContainer}
                      style={{
                        backgroundColor:
                          displayedScore === maxValues[period] ? '#D3D3D3' : 'transparent',
                      }}
                    >
                      {displayedScore}
                    </span>
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

PerQuarterTable.defaultProps = {
  sum: false,
}

export default PerQuarterTable
