import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const broadcastSwitch = async (data) => {
  const { broadcastId, orgId, switchToMediaId } = data
  const url = `${BASE_URL}/livestreams/${broadcastId}/switch?clubId=${orgId}&switchToMediaId=${switchToMediaId}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default broadcastSwitch
