import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getTactics = async () => {
  // const { q } = data
  // const url = `${BASE_URL}/tactics?Q=${q}`
  const url = `${BASE_URL}/tactics`

  const response = await fetch(url, {
    method: 'GET',
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response.json()
}

export default getTactics
