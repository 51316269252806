import React from 'react'
import { useField } from 'formik'
import { makeStyles, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import TEXT_FIELD_STYLES from '../../../utils/constants/CSS/textFieldStyles'

const MyTextField = (props) => {
  const {
    type,
    endAdornment,
    isFullWidth,
    handleOnBlur,
    variant,
    inputPropStyles,
    autoComplete,
    required,
    ...otherProps
  } = props

  const [field, meta] = useField(props)
  const { value, onBlur, ...rest } = field
  const { touched, error } = meta
  const useStyles = makeStyles(TEXT_FIELD_STYLES)
  const classes = useStyles()

  const isError = Boolean(
    (touched && error) || (touched && !value && required) || (!touched && error && required),
  )

  return (
    <TextField
      {...otherProps}
      {...rest}
      onBlur={(e) => {
        onBlur(e)
        if (handleOnBlur) {
          handleOnBlur(e)
        }
      }}
      value={value || ''}
      autoComplete={autoComplete ? '' : 'off'}
      type={type || 'input'}
      variant={variant || 'filled'}
      fullWidth={isFullWidth}
      helperText={touched && error ? error : ''}
      error={isError}
      required={required}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.root,
          input: classes.input,
        },
        endAdornment,
        style: inputPropStyles,
      }}
      InputLabelProps={{
        classes: {
          shrink: classes.shrink,
          formControl: classes.formControl,
          focused: classes.focused,
          error: classes.error,
        },
      }}
    />
  )
}

MyTextField.propTypes = {
  type: PropTypes.string,
  endAdornment: PropTypes.node,
  isFullWidth: PropTypes.bool,
  handleOnBlur: PropTypes.func,
  variant: PropTypes.string,
  inputPropStyles: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
}

MyTextField.defaultProps = {
  isFullWidth: true,
  autoComplete: 'off',
  type: 'text',
  variant: 'filled',
}

export default MyTextField
