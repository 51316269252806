import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const updateUserData = async (data) => {
  const { resetForm, ...restData } = data || {}
  const url = `${BASE_URL}/users/me`

  return axios.patch(url, restData, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default updateUserData
