import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const addDrill = (data) => {
  const url = `${BASE_URL}/training-modules`
  const { values } = data

  return axios.post(url, values, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default addDrill
