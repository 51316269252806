import React from 'react'
import BillingAddonActivation from './BillingAddonActivation'
import BillingAddonUsersSelection from './BillingAddonUsersSelection'
import BillingAddonConfirmation from './BillingAddonConfirmation'

const SubscriptionWizardContents = (props) => {
  const {
    billingAddon,
    usersToInvite,
    subscriptionDetails,
    setUsersToInvite,
    setPaymentSuccess,
    finish,
    activeStep,
    stepForward,
    stepBackwards,
  } = props

  const wizardStepContentMapper = {
    0: <BillingAddonActivation billingAddon={billingAddon} stepForward={stepForward} />,

    1: (
      <BillingAddonUsersSelection
        usersToInvite={usersToInvite}
        setUsersToInvite={setUsersToInvite}
        stepForward={stepForward}
      />
    ),

    2: (
      <BillingAddonConfirmation
        billingAddon={billingAddon}
        usersToInvite={usersToInvite}
        subscriptionDetails={subscriptionDetails}
        setPaymentSuccess={setPaymentSuccess}
        stepBackwards={stepBackwards}
        finish={finish}
      />
    ),
  }

  return wizardStepContentMapper[activeStep]
}

export default SubscriptionWizardContents
