import localStorageIds from './localStorageIds'

const generalHeader = (token) => ({
  accept: 'application/json',
  'Content-Type': 'application/json',
  'Club-Id': JSON.parse(localStorage.getItem(localStorageIds.SELECTED_ORG))?.id,
  ...(token && { Authorization: `Bearer ${token}` }),
})

export default generalHeader
