import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'
import { EVENT_TYPE_FORMS } from '../../utils/constants/eventTypeForms'

const getPractices = async (data) => {
  const { teamId, startDateTime, endDateTime, isPracticePlanIncluded } = data
  const queryString = `?EventTypeCode=${EVENT_TYPE_FORMS.PRACTICE}&TeamId=${teamId}&StartDateTime=${startDateTime}&EndDateTime=${endDateTime}&IsPracticePlanIncluded=${isPracticePlanIncluded}`
  const url = `${BASE_URL}/events${queryString}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getPractices
