const perQuarterTableStyles = () => ({
  tableHeader: { backgroundColor: '#E0726E', fontSize: '14px', textAlign: 'center' },
  tableCell: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'center',
  },
  spanContainer: {
    display: 'inline-block',
    padding: '5px 10px',
    borderRadius: '5px',
  },
})

export default perQuarterTableStyles
