import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getUploadVideo = async (data) => {
  const { trainingModuleId, file, requestBody } = data
  const url = `${BASE_URL}/training-modules/upload-video?trainingModuleId=${trainingModuleId}&filename=${file.name}`

  const response = await axios.post(url, requestBody, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getUploadVideo
