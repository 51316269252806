import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getTeamGamesStats = async ({ teamId }) => {
  const url = `${BASE_URL}/stats/team-games/${teamId}`

  return axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default getTeamGamesStats
