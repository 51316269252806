import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const cancelSubscription = async (data) => {
  const { orgId } = data
  const url = `${BASE_URL}/billing/cancel-subscription?clubId=${orgId}`

  return axios.delete(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default cancelSubscription
