import React, { useState } from 'react'
import { Collapse, Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Alert from '@material-ui/lab/Alert'
import appIntegrationStyles from './appIntegration.styles'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import TextButton from '../../../components/ui/TextButton/TextButton'

const AppIntegration = (props) => {
  const {
    linkMutation,
    unLinkMutation,
    icon: Icon,
    text,
    infoText,
    linkButton,
    isLoading,
    linkedAccountName,
    configurationForm,
  } = props
  const [expandedForm, setExpandedForm] = useState(false)
  const translate = useTranslate()
  const useStyles = makeStyles(appIntegrationStyles(expandedForm))
  const classes = useStyles()
  let button

  // TODO: If the component never gets used for another integration, simplify it only for YouTube. Otherwise, create a mapper.
  if (linkButton instanceof String) {
    button = (
      <TextButton label={linkButton} className={classes.button} onClick={linkMutation.mutate} />
    )
  } else {
    button = (
      <div style={{ cursor: 'pointer' }} onClick={linkMutation.mutate}>
        {linkButton}
      </div>
    )
  }

  return (
    <div>
      <Divider variant='middle' className={classes.divider} />
      <Container className={classes.containerApp}>
        <Grid item xs={12} className={classes.titleContainer}>
          {Icon}
        </Grid>
        <Grid item xs={12} className={classes.textContainer}>
          {text}
        </Grid>
        {isLoading ? (
          <LoaderDots style={{ marginLeft: 10 }} />
        ) : (
          <>
            <Alert
              variant='outlined'
              severity='info'
              style={{ backgroundColor: '#f4faff' }}
              className={classes.info}
            >
              {infoText}
            </Alert>
            <div className={classes.integrationFooter}>
              <Grid item xs={11} className={classes.buttonsContainer}>
                {!linkedAccountName ? button : <Typography>{linkedAccountName}</Typography>}
                {linkedAccountName && (
                  <TextButton
                    label={translate('ra.text.unlink')}
                    className={classes.button}
                    onClick={unLinkMutation.mutate}
                  />
                )}
              </Grid>
              {linkedAccountName && configurationForm && (
                <Grid
                  item
                  xs={1}
                  className={classes.configuration}
                  onClick={() => setExpandedForm((prevState) => !prevState)}
                >
                  <Typography color='primary'>Configuration</Typography>
                  <KeyboardArrowDown className={classes.arrow} />
                </Grid>
              )}
            </div>
          </>
        )}
        {linkedAccountName && configurationForm && (
          <Collapse in={expandedForm} timeout='auto' unmountOnExit>
            {configurationForm}
          </Collapse>
        )}
      </Container>
    </div>
  )
}

export default AppIntegration
