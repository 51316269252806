import { BASE_URL } from "../../utils/constants/url";
import generalHeader from "../../utils/constants/generalHeader";

export const deleteCourt = async (data) => {
  const { courtId } = data;
  const url = BASE_URL + `/courts/${courtId}`;

  return await fetch(url, {
    method: "DELETE",
    headers: generalHeader(localStorage.getItem('token'))
  });
}
