import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const updateTeam = async (data) => {
  const { teamId, ...rest } = data
  const url = `${BASE_URL}/teams/${teamId}`

  return axios.patch(url, rest, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default updateTeam
