import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const setCommentary = async (broadcastId, on) => {
  const url = `${BASE_URL}/livestreams/${broadcastId}/commentary`

  return axios.patch(
    url,
    {on: on},
    {
      headers: generalHeader(localStorage.getItem('token')),
    },
  )
}

export default setCommentary