import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getGameStats = async ({ gameId }) => {
  const url = `${BASE_URL}/stats/games/${gameId}`

  return axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default getGameStats
