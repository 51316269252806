import React from 'react'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'
import playerStatsTableStyles from './playerStatsTable.styles'
import statsHeaders from '../../utils/constants/statsHeaders'

const PlayerStatsTable = ({ playerData }) => {
  const useStyles = makeStyles(playerStatsTableStyles)
  const classes = useStyles()
  const translate = useTranslate()
  const headerCellsWithoutMin = Object.keys(statsHeaders).map((key) => (
    <TableCell key={key} className={classes.tableCell}>
      {statsHeaders[key]}
    </TableCell>
  ))

  const convertTimeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number)
    return hours * 3600 + minutes * 60 + seconds
  }

  const convertSecondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
  }

  const headerCellsWithoutMinGpGs = Object.keys(statsHeaders)
    .filter((key) => key !== 'gamesPlayed' && key !== 'gamesStarted')
    .map((key) => (
      <TableCell key={key} className={classes.tableCell}>
        {statsHeaders[key]}
      </TableCell>
    ))

  const roundToTwoDecimals = (number) => Math.round(number * 100) / 100

  const totalSeconds = convertTimeToSeconds(playerData.playerStats.minutes)
  const averageSeconds = totalSeconds / playerData.playerStats.gamesPlayed

  const playerObjects = {
    id: playerData.playerStats.playerId,
    fullName: playerData.playerStats.fullName,
    totalStats: [
      {
        gamesPlayed: playerData.playerStats.gamesPlayed,
        gamesStarted: playerData.playerStats.gamesStarted,
        minutes: playerData.playerStats.minutes,
        points: playerData.playerStats.gameStats.points,
        twoPointer: playerData.playerStats.gameStats.twoPointer,
        threePointer: playerData.playerStats.gameStats.threePointer,
        freeThrows: playerData.playerStats.gameStats.freeThrows,
        offensiveRebounds: playerData.playerStats.gameStats.offensiveRebounds,
        defensiveRebounds: playerData.playerStats.gameStats.defensiveRebounds,
        totalRebounds: playerData.playerStats.gameStats.totalRebounds,
        assists: playerData.playerStats.gameStats.assists,
        steals: playerData.playerStats.gameStats.steals,
        turnOvers: playerData.playerStats.gameStats.turnOvers,
        blocksAgainst: playerData.playerStats.gameStats.blocksAgainst,
        foulsCommited: playerData.playerStats.gameStats.foulsCommited,
        foulsDrawn: playerData.playerStats.gameStats.foulsDrawn,
        pir: playerData.playerStats.gameStats.pir,
      },
    ],

    avgStats: [
      {
        gamesPlayed: null,
        gamesStarted: roundToTwoDecimals(
          playerData.playerStats.gamesStarted / playerData.playerStats.gamesPlayed,
        ),
        minutes: convertSecondsToTime(averageSeconds),
        points: playerData.playerStats.gameStats.avgPoints,
        twoPointer: playerData.playerStats.gameStats.avgTwoPoints,
        threePointer: playerData.playerStats.gameStats.avgThreePoints,
        freeThrows: playerData.playerStats.gameStats.avgFreeThrows,
        offensiveRebounds: playerData.playerStats.gameStats.avgOffensiveRebounds,
        defensiveRebounds: playerData.playerStats.gameStats.avgDefensiveRebounds,
        totalRebounds: playerData.playerStats.gameStats.avgRebounds,
        assists: playerData.playerStats.gameStats.avgAssists,
        steals: playerData.playerStats.gameStats.avgSteals,
        turnOvers: playerData.playerStats.gameStats.avgTurnOvers,
        blocksAgainst: playerData.playerStats.gameStats.avgBlocksAgainst,
        foulsCommited: playerData.playerStats.gameStats.avgFoulsCommited,
        foulsDrawn: playerData.playerStats.gameStats.avgFoulsDrawn,
        pir: playerData.playerStats.gameStats.avgPIR,
      },
    ],
  }

  const mapPlayerObjectsToFormat = (playerData) => {
    const id = playerData.playerStats.playerId
    const fullName = playerData.playerStats.fullName
    const stats = playerData.teamPlayerStatsPerGame.map((game) => {
      return {
        Game: `vs ${game.opponentName}`,
        MIN: `${game.minutes}`,
        Pts: game.gameStats.points,
        FG2: `${game.gameStats.twoPointer}/${game.gameStats.totalTwoPointsAttempts}`,
        FG3: `${game.gameStats.threePointer}/${game.gameStats.totalThreePointsAttempts}`,
        FT: `${game.gameStats.freeThrows}/${game.gameStats.totalFreeThrowsAttempts}`,
        OR: game.gameStats.defensiveRebounds,
        DR: game.gameStats.defensiveRebounds,
        TR: game.gameStats.totalRebounds,
        As: game.gameStats.assists,
        St: game.gameStats.steals,
        To: game.gameStats.turnOvers,
        BLKA: game.gameStats.blocksAgainst,
        FC: game.gameStats.foulsCommited,
        FD: game.gameStats.foulsDrawn,
        PIR: game.gameStats.pir,
      }
    })

    return {
      id: id,
      fullName: fullName,
      stats: stats,
    }
  }

  const mappedPlayerData = mapPlayerObjectsToFormat(playerData)

  const renderTableRows = (stats, title) => {
    return stats.map((row, index) => (
      <TableRow key={index}>
        {title && (
          <TableCell>
            <Typography className={classes.typo}>{title}</Typography>
          </TableCell>
        )}

        {Object.entries(row).map(([key, value]) => (
          <TableCell key={key}>{value}</TableCell>
        ))}
      </TableRow>
    ))
  }

  return (
    <div>
      <Typography variant='h6' gutterBottom className={classes.marginTypo}>
        {translate('ra.text.seasonStats')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell />
              {headerCellsWithoutMin}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows(playerObjects.totalStats, 'Total')}</TableBody>
          <TableBody>{renderTableRows(playerObjects.avgStats, 'Average')}</TableBody>
        </Table>
      </TableContainer>

      <Typography variant='h6' gutterBottom className={classes.marginTypo}>
        {translate('ra.text.gameStats')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell />
              {headerCellsWithoutMinGpGs}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows(mappedPlayerData.stats.map((game) => game))}</TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default PlayerStatsTable
