import axios from 'axios'
import { BASE_URL } from '../../../utils/constants/url'
import generalHeader from '../../../utils/constants/generalHeader'

const createPlaybook = (data) => {
  const { teamId, trainingModulesIds } = data
  const url = `${BASE_URL}/play-books?teamId=${teamId}`
  const values = { trainingModulesIds }

  return axios.post(url, values, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default createPlaybook
