import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getUploadPresignedUrl = async (data) => {
  const { teamId, requestBody } = data
  const url = `${BASE_URL}/events/get-upload-presigned-url?teamId=${teamId}`

  const response = await axios.post(url, requestBody, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getUploadPresignedUrl
