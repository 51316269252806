import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { selectRenderValueStyles } from './selectRenderValue.styles'
import COLORS from '../../utils/constants/colors'
import DefaultOrgIcon from '../../assets/icons/DefaultOrgIcon'
import orgType from '../../types/orgType'
import teamType from '../../types/teamType'

const SelectRenderValue = (props) => {
  const { item, chosenOption, logoArgName, labelBlack } = props
  const useStyles = makeStyles(selectRenderValueStyles)
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {item[logoArgName] ? (
        <img src={item[logoArgName]} alt='logo' className={classes.img} />
      ) : (
        <DefaultOrgIcon className={classes.orgIcon} />
      )}
      <span
        style={{
          color: chosenOption.name === item.name && !labelBlack ? COLORS.orange : '#555555',
        }}
      >
        {item.name}
      </span>
    </div>
  )
}

SelectRenderValue.propTypes = {
  item: orgType,
  chosenOption: orgType || teamType,
  logoArgName: PropTypes.string,
  labelBlack: PropTypes.bool,
}

export default SelectRenderValue
