import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import { useRecordContext } from 'react-admin'
import userNameFieldStyles from './userNameField.styles'
import DefaultPlayerIcon from '../../../../assets/icons/defaultPlayerIcon'

const UserNameField = ({ recordScource }) => {
  const record = useRecordContext()
  const useStyles = makeStyles(userNameFieldStyles)
  const classes = useStyles()

  if (!record || !record[recordScource]) return <span>-</span>

  return (
    <div id='playerList' className={classes.userNameFieldWrapper}>
      {record?.logoUrl ? (
        <Avatar alt='avatar' src={record.logoUrl} className={classes.userNameFieldAvatar} />
      ) : (
        <DefaultPlayerIcon />
      )}
      <span>{record[recordScource].firstName}</span>
      <span>{record[recordScource].lastName}</span>
    </div>
  )
}

export default UserNameField
