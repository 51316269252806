import React from 'react'
import '../../../../utils/constants/CSS/eventForms.css'
import { Form, Formik, Field } from 'formik'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import MyTextField from '../../fields/MyTextField'
import MyDateTimeField from '../../fields/MyDateTimeField'
import MyMultilineTextField from '../../fields/MyMultilineTextField'
import FormContainer from '../../../layout/FormContainer/FormContainer'
import GetFormDirtyValue from '../../../getFormDirtyValue/getFormDirtyValue'
import parseEventFormSubmit from '../../../../utils/helpers/eventHelpers/parseEventFormSubmit'
import MyGroupIcon from '../../../../assets/icons/myGroupIcon'
import MyAutocomplete from '../../fields/MyAutocomplete'
import AutoChangeFakeEvent from '../../AutoChangeFakeEvent'
import mutationType from '../../../../types/mutationType'
import teamType from '../../../../types/teamType'
import eventVariantType from '../../../../types/events/eventVariantType'
import calendarDateClickType from '../../../../types/calendarDateClickType'
import eventType from '../../../../types/events/eventType'
import eventRawType from '../../../../types/events/eventRawType'
import {
  setEventEndTime,
  setEventStartTime,
} from '../../../../utils/helpers/eventHelpers/setEventTime'

const OtherEventForm = (props) => {
  const {
    clickedEventId,
    createEvent,
    updateEvent,
    event,
    selectedEventType,
    setFormDirty,
    currentTeam,
    teams,
    dateClick,
    eventsRaw,
    setEvents,
  } = props
  const { title, notes } = event || {}
  const translate = useTranslate()

  return (
    <Formik
      initialValues={{
        title: event ? title : '',
        startDateTime: setEventStartTime(event, dateClick),
        endDateTime: setEventEndTime(event, dateClick),
        teams: event?.teams ?? currentTeam,
        notes: event ? notes : '',
      }}
      onSubmit={(data) => {
        if (event) {
          updateEvent.mutate({
            formData: parseEventFormSubmit(data),
            eventId: clickedEventId,
            eventType: selectedEventType,
          })
        } else {
          createEvent.mutate({
            formData: parseEventFormSubmit(data),
            eventType: selectedEventType,
          })
        }
      }}
    >
      {() => (
        <Form id='my-form'>
          <FormContainer>
            <MyTextField label={translate('ra.formFields.eventTitle')} name='title' required />
            <MyDateTimeField
              name='startDateTime'
              label={translate('ra.formFields.from')}
              required
            />
            <MyDateTimeField name='endDateTime' label={translate('ra.formFields.to')} required />
            <div className='div-flex'>
              <MyGroupIcon />
              {currentTeam ? (
                event ? (
                  <span>{teams?.map((team) => team.name).join(', ')}</span>
                ) : (
                  <span>{currentTeam.name}</span>
                )
              ) : (
                <Field
                  name='teams'
                  label={translate('ra.formFields.teams')}
                  multiple
                  required
                  component={MyAutocomplete}
                  options={teams || []}
                  getOptionLabel={(option) => option.name}
                  defaultValue={event?.teams}
                  fullWidth
                />
              )}
            </div>
            <MyMultilineTextField
              name='notes'
              label={translate('ra.formFields.notes')}
              multiline
              minRows={5}
              fullWidth
            />
            <GetFormDirtyValue setFormDirty={setFormDirty} />
          </FormContainer>
          <AutoChangeFakeEvent
            clickedEventId={clickedEventId}
            selectedEventType={selectedEventType}
            eventsRaw={eventsRaw}
            setEvents={setEvents}
          />
        </Form>
      )}
    </Formik>
  )
}

OtherEventForm.propTypes = {
  clickedEventId: PropTypes.string,
  createEvent: mutationType,
  updateEvent: mutationType,
  event: eventType,
  selectedEventType: eventVariantType,
  setFormDirty: PropTypes.func,
  currentTeam: teamType,
  teams: PropTypes.arrayOf(teamType),
  dateClick: calendarDateClickType,
  eventsRaw: PropTypes.arrayOf(eventRawType),
  setEvents: PropTypes.func,
}

export default OtherEventForm
