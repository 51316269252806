import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const activateAddon = (data) => {
  const { userIds, orgId, type } = data
  const url = `${BASE_URL}/billing/subscriptions/${orgId}/addons/${type}`

  return axios.post(url, userIds, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default activateAddon
