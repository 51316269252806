import React from 'react'
import PropTypes from 'prop-types'
import MyTextField from '../form/fields/MyTextField'

const InvisibleValidationField = (props) => {
  const { fieldName, requireCondition } = props
  return (
    <div style={{ width: 0, height: 0 }}>
      <MyTextField name={fieldName} label='' required={requireCondition} />
    </div>
  )
}

InvisibleValidationField.propTypes = {
  fieldName: PropTypes.string,
  requireCondition: PropTypes.bool,
}

export default InvisibleValidationField
