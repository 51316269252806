import {
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender'
import COLORS from '../../utils/constants/colors'
import mainSubscriptionTabStyles from './MainSubscriptionTab/mainSubscriptionTab.styles'
import dateFormatter from '../../utils/helpers/dateHelpers/dateFormatter'

const useBillingSummaryStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      padding: '2px 10px',
    },
    '& .MuiTableRow-root': {
      height: '24px',
    },
  },
})

const BillingSummary = ({ subscriptionDetails }) => {
  const translate = useTranslate()
  const useStyles = makeStyles(mainSubscriptionTabStyles)
  const billingSummaryClasses = useBillingSummaryStyles()
  const classes = useStyles()

  const totalAmount =
    subscriptionDetails?.addons
      .filter((addon) => addon.enabled && addon.pricing)
      .reduce(
        (total, addon) => total + JSON.parse(addon.pricing)?.PricePerSeat * addon.seats.allocated,
        0,
      ) || 0

  const nextBillingDate = subscriptionDetails?.nextBillingDate
    ? dateFormatter(subscriptionDetails?.nextBillingDate)
    : null

  return (
    <Table className={`${classes.table} ${billingSummaryClasses.table}`}>
      <TableHead>
        <ConditionalRender
          renderValue={
            subscriptionDetails &&
            subscriptionDetails?.addons.filter((addon) => addon.enabled).length !== 0
          }
        >
          <TableRow style={{ backgroundColor: COLORS.opacBlue }}>
            <TableCell></TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              {translate('ra.text.perSeatCost')}
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              {translate('ra.text.allocatedSeats')}
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}></TableCell>
          </TableRow>
        </ConditionalRender>
      </TableHead>
      <TableBody>
        {subscriptionDetails?.addons
          .filter((addon) => addon.enabled)
          .map((addon) => (
            <TableRow key={addon.id}>
              <TableCell style={{ border: 'none' }}>
                {addon.id === 'Coach' ? 'Coaching' : addon.id}
              </TableCell>
              <TableCell style={{ textAlign: 'center', border: 'none' }}>
                {addon.id ? `€${JSON.parse(addon.pricing)?.PricePerSeat.toFixed(2)}` : '-'}
              </TableCell>
              <TableCell style={{ textAlign: 'center', border: 'none' }}>
                {addon.id ? addon.seats.allocated : '-'}
              </TableCell>
              <TableCell style={{ textAlign: 'center', border: 'none' }}>
                {addon.id
                  ? `€${(JSON.parse(addon.pricing)?.PricePerSeat * addon.seats.allocated).toFixed(2)}`
                  : '-'}
              </TableCell>
            </TableRow>
          ))}
        <ConditionalRender
          renderValue={
            !!subscriptionDetails?.coupon &&
            subscriptionDetails &&
            subscriptionDetails?.addons.filter((addon) => addon.enabled).length !== 0
          }
        >
          <TableRow>
            <TableCell colSpan={3}>Coupon</TableCell>
            <TableCell style={{ textAlign: 'center' }} colSpan={1}>
              {subscriptionDetails?.coupon && `-${subscriptionDetails?.coupon.discountPercentage}%`}
            </TableCell>
          </TableRow>
        </ConditionalRender>
        <TableRow>
          <TableCell colSpan={3} style={{ borderTop: '1px solid #d3d3d3' }}>
            {nextBillingDate && `${translate('ra.text.nextBillingDate')}: ${nextBillingDate}`}
          </TableCell>
          <TableCell style={{ textAlign: 'center', borderTop: '1px solid #d3d3d3' }}>
            <Typography style={{ fontWeight: 'bold' }}>
              €
              {totalAmount.toFixed(2) -
                (subscriptionDetails?.coupon
                  ? (totalAmount.toFixed(2) * subscriptionDetails?.coupon.discountPercentage) / 100
                  : 0)}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

BillingSummary.propTypes = {
  subscriptionDetails: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    totalSeats: PropTypes.number,
    allocatedSeats: PropTypes.number,
    availableSeats: PropTypes.number,
    nextBillingDate: PropTypes.string,
    vivaPaymentOrder: PropTypes.any,
    vivaPaymentOrderInitialTransactionId: PropTypes.any,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    streetNumber: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string,
    companyName: PropTypes.string,
    vatNumber: PropTypes.string,
    activity: PropTypes.string,
    needsInvoice: PropTypes.bool,
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        pricing: PropTypes.string,
        seats: PropTypes.shape({
          all: PropTypes.number,
          allocated: PropTypes.number,
          free: PropTypes.number,
        }),
        enabled: PropTypes.bool,
        userActivatable: PropTypes.bool,
      }),
    ),
    cardFourLastDigits: PropTypes.string,
    coupon: PropTypes.any,
  }),
}

export default BillingSummary
