import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const impersonateUser = (data) => {
  const { userId } = data
  const url = `${BASE_URL}/auth/impersonate?userId=${userId}`

  return axios.post(url, null, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default impersonateUser
