import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const verifyTransaction = async (data) => {
  const { orgId, vivaPaymentOrderInitialTransactionId } = data
  const url = `${BASE_URL}/billing/verify-transaction?orgId=${orgId}&vivaPaymentOrderInitialTransactionId=${vivaPaymentOrderInitialTransactionId}`

  return axios.patch(url, null, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default verifyTransaction
