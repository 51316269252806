import COLORS from '../../../../utils/constants/colors'

const imageCropEditPreviewStyles = (width) => {
  const DEFAULT_DIMENSION_VALUE = 130

  const dimensionValue = width ?? DEFAULT_DIMENSION_VALUE
  const editDimensionValue = (dimensionValue * 18) / 100

  return {
    container: {
      position: 'relative',
      width: dimensionValue,
      height: dimensionValue,
    },
    img: {
      width: dimensionValue,
      height: dimensionValue,
    },
    emptyImg: {
      padding: 10,
      backgroundColor: '#eeeeee',
      borderRadius: '50%',
      width: dimensionValue - 20,
      height: dimensionValue - 20,
    },
    editIcon: {
      position: 'absolute',
      right: '10%',
      bottom: '-1%',
      width: editDimensionValue,
      height: editDimensionValue,
      padding: 3,
      cursor: 'pointer',
      borderRadius: '50%',
      backgroundColor: COLORS.orange,
      fill: COLORS.white,
    },
  }
}

export default imageCropEditPreviewStyles
