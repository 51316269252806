import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getDrills = async (data) => {
  const { access, pageNumber, intensities, positions, tactics, q, type } = data

  const createFiltersQueryString = (values, typeName) => {
    if (values && values.length > 0) {
      return `&${typeName}=${values.map((value) => value).join()}`
    }
    return ''
  }

  const intensityQueryString = createFiltersQueryString(intensities, 'Intensities')
  const positionQueryString = createFiltersQueryString(positions, 'Positions')
  const tacticQueryString = createFiltersQueryString(tactics, 'Tactics')
  const searchQueryString = q ? `&Q=${q}` : ''

  const queryString = `?Access=${access}&Page=${pageNumber}${intensityQueryString}${positionQueryString}${tacticQueryString}${searchQueryString}&Type=${type}`
  const url = `${BASE_URL}/training-modules${queryString}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getDrills
