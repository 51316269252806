import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import PropTypes from 'prop-types'
import decodeJwt from 'jwt-decode'
import { BASE_URL } from '../utils/constants/url'
import localStorageIds from '../utils/constants/localStorageIds'

const SignalRConnectionContext = createContext(null)

export const useSignalRConnectionContext = () => useContext(SignalRConnectionContext)

export const SignalRConnectionProvider = ({ children }) => {
  const [connection, setConnection] = useState(null)
  const [connectionError, setConnectionError] = useState(null)
  const translate = useTranslate()

  useEffect(() => {
    // Check if token exists
    const storedToken = localStorage.getItem(localStorageIds.TOKEN)
    // Decode it
    const token = storedToken ? decodeJwt(storedToken) : null
    // Take the userId
    const id = token && token.sub ? token.sub : null
    const initializeSignalR = async () => {
      try {
        if (token) {
          const conn = new HubConnectionBuilder()
            .withUrl(`${BASE_URL}/notifications?userId=${id}`, { withCredentials: false })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.None)
            .build()

          conn.onclose((error) => {
            if (error) {
              setConnectionError(translate('ra.notification.serverError'))
            } else {
              setConnectionError(null)
            }
          })

          conn.onreconnected(() => {
            setConnectionError(null)
          })

          await conn.start()
          setConnection(conn)
        }
      } catch (error) {
        setConnectionError(translate('ra.notification.serverError'))
      }
    }

    initializeSignalR()

    // Cleanup function
    return () => {
      if (connection) {
        connection.stop()
      }
    }
  }, [])

  const contextValue = useMemo(
    () => ({ connection, connectionError }),
    [connection, connectionError],
  )

  return (
    <SignalRConnectionContext.Provider value={contextValue}>
      {children}
    </SignalRConnectionContext.Provider>
  )
}

SignalRConnectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
