import { React } from 'react'
import { Box, makeStyles, Card, CardContent, Typography, Avatar } from '@material-ui/core'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'
import { useTranslate } from 'react-admin'
import MyChip from '../../components/MyChip/MyChip'

const PlayerInfoCard = ({ playerInfo }) => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Card className={classes.teamCard}>
      {playerInfo?.logoUrl ? (
        <Avatar className={classes.teamLogo} src={playerInfo.logoUrl} />
      ) : (
        <Avatar className={classes.teamLogo} src='' />
      )}
      <CardContent className={classes.teamCardContent}>
        <Typography variant='h5'>{`${playerInfo.personalData.firstName} ${playerInfo.personalData.lastName}`}</Typography>
        <Typography variant='subtitle1'>{playerInfo.teams[0].name}</Typography>
        <Box className={classes.infoBox}>
          <Typography variant='body1'>
            {translate('ra.text.jerseyNumber')} {playerInfo.teams[0].jerseyNumber}
          </Typography>
          <Typography variant='body1'>
            {translate('ra.text.height')} {playerInfo.personalData.height || '-'}
          </Typography>
          <Typography variant='body1'>
            {translate('ra.text.positions')}
            {playerInfo.personalData.positions.map((position) => (
              <MyChip label={position.name} small />
            ))}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default PlayerInfoCard
