import React from 'react'
import { makeStyles, Typography, CardMedia } from '@material-ui/core'
import gameScoreStyles from './gameScore.styles'
import DefaultOrgIcon from '../../assets/icons/DefaultOrgIcon'

const GameScore = ({ firstTeam, secondTeam, place }) => {
  const useStyles = makeStyles(gameScoreStyles)
  const classes = useStyles()
  const isFirstTeamHigher = firstTeam.teamScore > secondTeam.teamScore
  const isSecondTeamHigher = secondTeam.teamScore > firstTeam.teamScore

  return (
    <div className={classes.gameContainer}>
      {place === 'Away' ? (
        <>
          <div className={classes.teamContainer}>
            {secondTeam?.teamLogo ? (
              <CardMedia className={classes.teamLogo} image={secondTeam.teamLogo} />
            ) : (
              <DefaultOrgIcon className={classes.teamLogo} />
            )}
            <Typography variant='subtitle1' className={classes.teamName}>
              {secondTeam.teamName}
            </Typography>
          </div>
          <div className={isSecondTeamHigher ? classes.highScore : classes.score}>
            <Typography variant='h6' className={classes.scoreNumber}>
              {secondTeam.teamScore}
            </Typography>
          </div>
          <div className={isFirstTeamHigher ? classes.highScore : classes.score}>
            <Typography variant='h6' className={classes.scoreNumber}>
              {firstTeam.teamScore}
            </Typography>
          </div>
          <div className={classes.teamContainer}>
            <Typography variant='subtitle1' className={classes.teamName}>
              {firstTeam.teamName}
            </Typography>
            {firstTeam?.teamLogo ? (
              <CardMedia className={classes.teamLogo} image={firstTeam?.teamLogo} />
            ) : (
              <DefaultOrgIcon className={classes.teamLogo} />
            )}
          </div>
        </>
      ) : (
        <>
          <div className={classes.teamContainer}>
            {firstTeam?.teamLogo ? (
              <CardMedia className={classes.teamLogo} image={firstTeam?.teamLogo} />
            ) : (
              <DefaultOrgIcon className={classes.teamLogo} />
            )}
            <Typography variant='subtitle1' className={classes.teamName}>
              {firstTeam.teamName}
            </Typography>
          </div>
          <div className={isFirstTeamHigher ? classes.highScore : classes.score}>
            <Typography variant='h6' className={classes.scoreNumber}>
              {firstTeam.teamScore}
            </Typography>
          </div>
          <div className={isSecondTeamHigher ? classes.highScore : classes.score}>
            <Typography variant='h6' className={classes.scoreNumber}>
              {secondTeam.teamScore}
            </Typography>
          </div>
          <div className={classes.teamContainer}>
            <Typography variant='subtitle1' className={classes.teamName}>
              {secondTeam.teamName}
            </Typography>
            {secondTeam?.teamLogo ? (
              <CardMedia className={classes.teamLogo} image={secondTeam.teamLogo} />
            ) : (
              <DefaultOrgIcon className={classes.teamLogo} />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default GameScore
