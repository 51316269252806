import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const patchDrill = (data) => {
  const { values, trainingModuleId } = data
  const url = `${BASE_URL}/training-modules/${trainingModuleId}`

  return axios.patch(url, values, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default patchDrill
