import axios from 'axios'
import { stringify } from 'query-string'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getOrgPlayers = async ({ orgId }) => {
  const queryParams = { OrderBy: '-FirstName', clubId: orgId, page: 1, perPage: 50 }

  const url = `${BASE_URL}/players?${stringify(queryParams)}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getOrgPlayers
