import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import AddIcon from '../../../assets/icons/addIcon'
import addButtonStyles from './addButton.styles'
import COLORS from '../../../utils/constants/colors'

const AddButton = (props) => {
  const { label, icon, noHoverEffect, classNameText, style, filled } = props
  const useStyles = makeStyles(addButtonStyles(filled))
  const classes = useStyles()

  return (
    <Button
      {...props}
      startIcon={icon ?? <AddIcon filled={filled} />}
      style={filled ? { backgroundColor: COLORS.orange, ...style } : { ...style }}
      classes={{ root: noHoverEffect && classes.noHoverEffect }}
    >
      <span className={classNameText ?? classes.buttonLabel}>{label}</span>
    </Button>
  )
}

AddButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  className: PropTypes.string,
  noHoverEffect: PropTypes.bool,
  classNameText: PropTypes.string,
  filled: PropTypes.bool,
}

export default AddButton
