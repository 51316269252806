import playerIdBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/player/playerIdBreadcrumb'
import drillBreadcrumbEdit from '../../components/Breadcrumb/breadcrumbFunctions/drill/drillBreadcrumbEdit'
import playBreadcrumbEdit from '../../components/Breadcrumb/breadcrumbFunctions/play/playBreadcrumbEdit'
import practiceBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/practice/practiceBreadcrumb'
import practicePlanBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/practice/practicePlanBreadcrumb'
import practicePlanIdBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/practice/practicePlanIdBreadcrumb'
import playerCreateBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/player/playerCreateBreadcrumb'
import playerBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/player/playerBreadcrumb'
import teamCalendarBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/calendar/teamCalendarBreadcrumb'
import calendarBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/calendar/calendarBreadcrumb'
import calendarIdBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/calendar/calendarIdBreadcrumb'
import teamCalendarIdBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/calendar/teamCalendarIdBreadcrumb'
import practiceIdBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/practice/practiceIdBreadcrumb'
import practicePlanShowBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/practice/practicePlanShowBreadcrumb'
import practicePlayersShowBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/practice/practicePlayersShowBreadCrumb'
import practicePlayersIdBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/practice/practicePlayersIdBreadCrumb copy'
import playerStatsBreadcrumb from '../../components/Breadcrumb/breadcrumbFunctions/stats/playerStatsBreadcrumb'

const BREADCRUMB_ROUTES = [
  { path: '/calendar', breadcrumb: calendarBreadcrumb },
  { path: '/calendar/:eventId', breadcrumb: calendarIdBreadcrumb },

  { path: '/teamCalendar', breadcrumb: teamCalendarBreadcrumb },
  { path: '/teamCalendar/:eventId', breadcrumb: teamCalendarIdBreadcrumb },

  // Create should be above id
  { path: '/players', breadcrumb: playerBreadcrumb },
  { path: '/players/create', breadcrumb: playerCreateBreadcrumb },
  { path: '/players/:playerId', breadcrumb: playerIdBreadcrumb },

  { path: '/drills', breadcrumb: () => ['DRILLS'] },
  { path: '/drills/create', breadcrumb: () => ['DRILLS', 'CREATE'] },
  { path: '/drills/:drillId', breadcrumb: drillBreadcrumbEdit },

  { path: '/plays', breadcrumb: () => ['PLAYS'] },
  { path: '/plays/create', breadcrumb: () => ['PLAYS', 'CREATE'] },
  { path: '/plays/:playId', breadcrumb: playBreadcrumbEdit },

  { path: '/tactics', breadcrumb: () => ['TACTICS'] },

  { path: '/profile', breadcrumb: () => ['PROFILE'] },

  { path: '/orgSettings', breadcrumb: () => ['ORGANISATION SETTINGS'] },
  { path: '/subscription', breadcrumb: () => ['ORGANISATION SUBSCRIPTION'] },

  { path: '/teamSettings', breadcrumb: () => ['TEAM SETTINGS'] },
  { path: '/statistics', breadcrumb: () => ['TEAM STATISTICS'] },
  { path: '/game-statistics', breadcrumb: () => ['GAME STATISTICS'] },

  { path: '/practice', breadcrumb: practiceBreadcrumb },
  { path: '/practice/:practiceId', breadcrumb: practiceIdBreadcrumb },
  { path: '/practice-plan/', breadcrumb: practicePlanBreadcrumb },
  { path: '/practice-plan/:practicePlanId/show', breadcrumb: practicePlanShowBreadcrumb },
  { path: '/practice-plan/:practicePlanId', breadcrumb: practicePlanIdBreadcrumb },
  { path: '/practice-players/:practicePlayersId/show', breadcrumb: practicePlayersShowBreadcrumb },
  { path: '/practice-players', breadcrumb: practicePlayersIdBreadcrumb },
  { path: '/player-statistics/:playerId', breadcrumb: playerStatsBreadcrumb },
  { path: '/player-statistics/:playerId', breadcrumb: playerStatsBreadcrumb },
]

export default BREADCRUMB_ROUTES
