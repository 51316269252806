import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const createEvent = (data) => {
  const { formData, eventType } = data
  const newData = {
    ...formData,
    eventTypeId: eventType.id,
  }
  const url = `${BASE_URL}/events/${data.eventType.code}`

  return axios.post(url, newData, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default createEvent
