import React from 'react'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import MyTextField from '../../../fields/MyTextField'
import MySelectField from '../../../fields/MySelectField/MySelectField'
import genderType from '../../../../../types/genderType'
import ageRestrictionType from '../../../../../types/ageRestrictionType'
import ImageCrop from '../../../../ui/ImageCropComponents/ImageCrop/ImageCrop'

const AddTeamFormBody = (props) => {
  const {
    setFieldValue,
    gender,
    ageRestriction,
    nameTextFieldInfo,
    shortNameLabel,
    setCroppedTeamImage,
  } = props
  const translate = useTranslate()

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Grid
        container
        spacing={2}
        xs={6}
        style={{ marginTop: 20, flexDirection: 'column', justifyContent: 'space-evenly' }}
      >
        <MyTextField
          md={6}
          name={nameTextFieldInfo?.name ?? 'name'}
          label={nameTextFieldInfo?.label ?? translate('ra.formFields.name')}
          required
          handleOnBlur={(e) => {
            setFieldValue('threeLetterIdentifier', e.target.value.toUpperCase().substring(0, 3))
          }}
        />
        <MyTextField
          md={6}
          label={shortNameLabel ?? translate('ra.formFields.shortName')}
          name='threeLetterIdentifier'
          required
        />
        <MySelectField
          md={6}
          name='ageRestriction'
          label={translate('ra.formFields.ageGroup')}
          required
          options={ageRestriction}
          fullWidth
        />
        <MySelectField
          md={6}
          name='gender'
          label={translate('ra.formFields.gender')}
          required
          options={gender}
          fullWidth
        />
      </Grid>
      <div style={{ marginLeft: 70 }}>
        <div>{translate('ra.text.teamAvatar')}</div>
        <Grid
          item
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          xs={6}
          style={{ marginTop: 10 }}
        >
          <ImageCrop
            setCroppedImage={setCroppedTeamImage}
            width={240}
            height={240}
            addImageLabel={translate('ra.buttons.uploadTeamLogo')}
          />
        </Grid>
      </div>
    </div>
  )
}

AddTeamFormBody.propTypes = {
  setFieldValue: PropTypes.func,
  gender: PropTypes.arrayOf(genderType),
  ageRestriction: PropTypes.arrayOf(ageRestrictionType),
  nameTextFieldInfo: PropTypes.shape({
    name: Promise.name,
    label: PropTypes.string,
  }),
  shortNameLabel: PropTypes.string,
  setCroppedTeamImage: PropTypes.func,
}

AddTeamFormBody.defaultProps = {
  gender: [],
  ageRestriction: [],
}

export default AddTeamFormBody
