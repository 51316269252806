import React from 'react'
import {
  makeStyles,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Container,
} from '@material-ui/core'
import gamesListStyles from './gameList.styles'
import useHistoryPush from '../../hooks/useHistoryPush'
import { format } from 'date-fns'
import QueryKeys from '../../utils/constants/queryKeys'
import { useQuery } from 'react-query'
import useQueryParams from '../../hooks/useQueryParams'
import COLORS from '../../utils/constants/colors'
import getTeamGamesStats from '../../Apis/stats/getTeamGamesStats'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import GameScore from './GameScore'

const GamesList = () => {
  const useStyles = makeStyles(gamesListStyles)
  const classes = useStyles()
  const { redirect } = useHistoryPush()
  const queryParams = useQueryParams()

  const { data: games, isFetching: isFetching } = useQuery(
    [QueryKeys.GET_TEAM_GAMES_STATS],
    () => getTeamGamesStats({ teamId: queryParams?.team }),
    {
      enabled: Boolean(queryParams?.team),
    },
  )

  const handleGameClick = (eventId) => {
    redirect(`/game-statistics`, [{ eventId }])
  }

  return (
    <Container>
      {isFetching ? (
        <ContainerPadding paddingValue='45%'>
          <LoaderDots
            style={{
              fill: COLORS.orange,
              width: 70,
              height: 70,
            }}
          />
        </ContainerPadding>
      ) : (
        <Paper className={classes.root}>
          <TableContainer>
            <Table>
              <TableBody>
                {games?.data.map((game) => {
                  const formattedDate = format(new Date(game.startDateTime), 'dd MMM yyyy, HH:mm')

                  return (
                    <TableRow
                      key={game.gameEventid}
                      className={classes.game}
                      onClick={() => handleGameClick(game.gameEventid)}
                    >
                      <TableCell style={{ width: '15%' }}>
                        <Typography className={classes.gameDate} variant='body2'>
                          {formattedDate}
                          {game.competitionName && (
                            <Typography className={classes.notes} variant='body2'>
                              {game.competitionName}
                            </Typography>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: '70%' }}>
                        <GameScore
                          firstTeam={game.team1}
                          secondTeam={game.team2}
                          place={game.place}
                        />
                      </TableCell>
                      <TableCell style={{ width: '15%' }}>
                        <button className={classes.finalScoreBtn}>Game</button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Container>
  )
}

export default GamesList
