import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const deleteEvent = async (data) => {
  const { eventTypeCode, eventId } = data
  const url = `${BASE_URL}/events/${eventTypeCode}/${eventId}`

  return axios.delete(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default deleteEvent
