import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const deactivateAddon = (data) => {
  const { orgId, type } = data
  const url = `${BASE_URL}/billing/subscriptions/${orgId}/addons/${type}`

  return axios.delete(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default deactivateAddon
