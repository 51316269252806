import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getEvents = async (data) => {
  const { teams, org, startDateTime, endDateTime } = data
  if (!teams && !org) return null

  const teamQueryString = `teamId=${teams}`
  const orgQueryString = `clubId=${org}`

  const queryString = `?startDateTime=${startDateTime}&endDateTime=${endDateTime}&${
    org ? orgQueryString : teamQueryString
  }`

  const url = `${BASE_URL}/events${queryString}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getEvents
