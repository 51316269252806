import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const addTactic = async (data) => {
  const url = `${BASE_URL}/tactics`

  return axios.post(url, data, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default addTactic
