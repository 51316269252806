import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const invitationAccept = async (data) => {
  const { invitationId } = data
  const url = `${BASE_URL}/Invitations/${invitationId}/accept`

  return axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default invitationAccept
