import React, { useEffect, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import { useTranslate } from 'react-admin'
import {
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import MyTextField from '../form/fields/MyTextField'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../utils/constants/gameEventFormBroadcastFields'
import { isFieldDisabled } from '../../utils/helpers/isFieldDisabled'
import CROPPER_SHAPES from '../../utils/constants/cropperShapes'
import CourtLogosPreviewEditBox from '../CourtLogosPreviewEditBox/CourtLogosPreviewEditBox'
import ImageCropWithEdit from '../ui/ImageCropComponents/ImageCropWithEdit/ImageCropWithEdit'
import MyAutocomplete from '../form/fields/MyAutocomplete'
import QueryKeys from '../../utils/constants/queryKeys'
import getPrivacyStatus from '../../Apis/event/getPrivacyStatus'
import InvisibleValidationField from '../InvisibleValidationField/InvisibleValidationField'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import StreamField from './StreamFields/StreamField'
import youtubeStreamFieldsStyles from './youtubeStreamFields.styles'

const YoutubeStreamFields = (props) => {
  const { state, broadcastId, allowOrgYoutube, emptyThumbnailNotAllowed } = props
  const { values, setFieldValue } = useFormikContext()
  const [youtubeConfig, setYoutubeConfig] = useState(
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG],
  )
  const [streamKey, setStreamKey] = useState(
    values[GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY],
  )
  const [useOrgsYoutube, setUseOrgsYoutube] = useState(
    !!broadcastId || !values[GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY],
  )
  const translate = useTranslate()
  const useStyles = makeStyles(
    youtubeStreamFieldsStyles(
      Boolean(
        values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]?.[
          GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_THUMBNAIL
        ],
      ),
    ),
  )
  const classes = useStyles()

  // TODO: Uplift the useOrgsYoutube state in formik, so the values ytConfig values and streamKey are nulled in parse and not here (will remove states, useEffects and make fields null)
  useEffect(() => {
    if (values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG] !== null) {
      setYoutubeConfig(values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG])
      setUseOrgsYoutube(true)
    }
  }, [values])

  useEffect(() => {
    if (values[GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY] !== null) {
      setStreamKey(values[GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY])
      setUseOrgsYoutube(false)
    }
  }, [values])

  const { data: privacyStatuses } = useQuery([QueryKeys.GET_PRIVACY_STATUS], () =>
    getPrivacyStatus(),
  )

  const thumbnailPath = `${GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG}[${GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_THUMBNAIL}]`
  const titlePath = `${GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG}[${GAME_EVENT_FORM_BROADCAST_FIELDS.BROADCAST_TITLE}]`
  const visibilityPath = `${GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG}[${GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_VISIBILITY}]`

  const setCroppedImage = (name, value) => setFieldValue(name, value)

  // TODO: Find better way to update youtube config and youtubeStreamKey
  const handleChangeRadio = (useYoutubeSelected) => {
    if (useYoutubeSelected) {
      setFieldValue(GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY, null)
      return
    }

    setFieldValue(GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG, null)
  }

  const handleChangeRadioEnter = (useYoutubeSelected) => {
    if (useYoutubeSelected) {
      setFieldValue(GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG, {
        broadcastId,
        ...youtubeConfig,
      })
      return
    }
    setFieldValue(GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY, streamKey)
  }

  const onVisibilityChange = (e, setVisibility) => {
    const selectedValue = e.target.textContent
    setVisibility(visibilityPath, selectedValue)
  }

  // When youtube is not linked
  if (!allowOrgYoutube) {
    return <StreamField state={state} />
  }

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby='demo-controlled-radio-buttons-group'
        name='controlled-radio-buttons-group'
        value={useOrgsYoutube ? 'orgYoutube' : 'streamKey'}
        onChange={() => setUseOrgsYoutube((prevState) => !prevState)}
      >
        <FormControlLabel
          value='orgYoutube'
          control={<Radio size='small' color='primary' />}
          label={translate('ra.text.useYoutube')}
        />
        <Collapse
          in={useOrgsYoutube}
          timeout='auto'
          onExited={() => handleChangeRadio(useOrgsYoutube)}
          onEnter={() => handleChangeRadioEnter(useOrgsYoutube)}
        >
          <Grid container>
            <div className={classes.thumbnailContainer}>
              <InvisibleValidationField
                fieldName={thumbnailPath}
                requireCondition={
                  useOrgsYoutube &&
                  emptyThumbnailNotAllowed &&
                  !values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]?.[
                    GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_THUMBNAIL
                  ]
                }
              />
              <ImageCropWithEdit
                defaultImageSrc={
                  values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]?.[
                    GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_THUMBNAIL
                  ] ?? ''
                }
                setCroppedImage={(croppedImage) => setCroppedImage(thumbnailPath, croppedImage)}
                aspect={16 / 9}
                minZoom={0.2}
                cropShape={CROPPER_SHAPES.RECT}
                dialogBottomText={translate('ra.text.thumbnailEdit')}
                isDisabled={isFieldDisabled(state)}
                editIconSize='2vw'
                EditPreviewComponent={CourtLogosPreviewEditBox}
                editPreviewComponentClassName={
                  values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG] &&
                  values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG][
                    GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_THUMBNAIL
                  ]
                    ? classes.thumbnailLogoFilled
                    : classes.thumbnailLogo
                }
              />
              <span>
                {!values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]?.[
                  GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_THUMBNAIL
                ] && translate('ra.text.enterThumbnail')}
              </span>
            </div>

            <MyTextField
              name={titlePath}
              label={translate('ra.formFields.youtubeVideoTitle')}
              className={classes.fieldSpacing}
              required={useOrgsYoutube}
              disabled={isFieldDisabled(state, GAME_EVENT_FORM_BROADCAST_FIELDS.BROADCAST_TITLE)}
            />
            <Field
              name={visibilityPath}
              label={translate('ra.formFields.youtubeVisibility')}
              className={classes.fieldSpacing}
              fullWidth
              variant='filled'
              options={privacyStatuses?.map((status) => status.displayName) || []}
              component={MyAutocomplete}
              onChange={(e) => onVisibilityChange(e, setFieldValue)}
              value={
                values[GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]?.[
                  GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_VISIBILITY
                ]
              }
              disabled={isFieldDisabled(state, GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_VISIBILITY)}
              autoSelect={false}
              required={useOrgsYoutube}
            />
          </Grid>
        </Collapse>
        <FormControlLabel
          value='streamKey'
          control={<Radio size='small' color='primary' />}
          label={translate('ra.text.differentYoutubeChannel')}
        />
        <ConditionalRender renderValue={!useOrgsYoutube}>
          <Collapse
            in={!useOrgsYoutube}
            timeout='auto'
            onExited={() => handleChangeRadio(useOrgsYoutube)}
            onEnter={() => handleChangeRadioEnter(useOrgsYoutube)}
            style={{ marginTop: 10 }}
          >
            <StreamField state={state} />
          </Collapse>
        </ConditionalRender>
      </RadioGroup>
    </FormControl>
  )
}

YoutubeStreamFields.propTypes = {
  state: PropTypes.string,
  broadcastId: PropTypes.string,
  allowOrgYoutube: PropTypes.bool,
  emptyThumbnailNotAllowed: PropTypes.bool,
}

export default YoutubeStreamFields
