import { shape, string } from 'prop-types'

const broadcastType = shape({
  awayTeamLogo: string,
  awayTeamLogoPreview: string,
  awayTeamName: string,
  competitionLogo: string,
  competitionName: string,
  courtBottomLeftLogo: string,
  courtBottomRightLogo: string,
  courtCenterLogo: string,
  courtTopLeftLogo: string,
  courtTopRightLogo: string,
  homeTeamLogo: string,
  homeTeamLogoPreview: string,
  homeTeamName: string,
  id: string,
  saveType: string,
  state: string,
  streamerInputUrl: string,
  streamerSaveUrl: string,
  title: string,
  type: string,
  youtubeStreamKey: string,
})

export default broadcastType
