import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

export const accessDrill = async (drillId) => {
  const url = BASE_URL + `/training-modules/${drillId}/download`

  const response = await fetch(url, {
    method: 'GET',
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response.json()
}
