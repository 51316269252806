import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import useHistoryPush from '../../hooks/useHistoryPush'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'
import statsHeaders from '../../utils/constants/statsHeaders'

const PlayersTable = ({ players, showValue }) => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const classes = useStyles()
  const translate = useTranslate()
  const { redirect } = useHistoryPush()

  if (!players || players.length === 0) {
    return null
  }
  const roundToTwoDecimals = (number) => Math.round(number * 100) / 100

  const playerObjects = players?.map((player) => {
    return {
      id: player.playerId,
      fullName: player.fullName,
      totalStats: {
        gamesPlayed: player.gamesPlayed,
        gamesStarted: player.gamesStarted,
        points: player.gameStats.points,
        twoPointer: player.gameStats.twoPointer,
        threePointer: player.gameStats.threePointer,
        freeThrows: player.gameStats.freeThrows,
        offensiveRebounds: player.gameStats.offensiveRebounds,
        defensiveRebounds: player.gameStats.defensiveRebounds,
        totalRebounds: player.gameStats.totalRebounds,
        assists: player.gameStats.assists,
        steals: player.gameStats.steals,
        turnOvers: player.gameStats.turnOvers,
        blocksAgainst: player.gameStats.blocksAgainst,
        foulsCommited: player.gameStats.foulsCommited,
        foulsDrawn: player.gameStats.foulsDrawn,
        pir: player.gameStats.pir,
      },
      avgStats: {
        gamesPlayed: player.gamesPlayed,
        gamesStarted: roundToTwoDecimals(player.gamesStarted / player.gamesPlayed),
        points: player.gameStats.avgPoints,
        twoPointer: player.gameStats.avgTwoPoints,
        threePointer: player.gameStats.avgThreePoints,
        freeThrows: player.gameStats.avgFreeThrows,
        totalRebounds: player.gameStats.avgRebounds,
        defensiveRebounds: player.gameStats.avgDefensiveRebounds,
        offensiveRebounds: player.gameStats.avgOffensiveRebounds,
        assists: player.gameStats.avgAssists,
        steals: player.gameStats.avgSteals,
        turnOvers: player.gameStats.avgTurnOvers,
        blocksAgainst: player.gameStats.avgBlocksAgainst,
        foulsCommited: player.gameStats.avgFoulsCommited,
        foulsDrawn: player.gameStats.avgFoulsDrawn,
        pir: player.gameStats.avgPIR,
      },
    }
  })

  const headers = Object.keys(playerObjects[0]?.totalStats)

  const handleRowClick = (playerId) => {
    redirect(`/player-statistics/${playerId}`)
  }

  return (
    <Table>
      <TableHead>
        <TableRow style={{ backgroundColor: '#E0726E' }}>
          <TableCell align='left'>{translate('ra.text.players')}</TableCell>
          {headers.map((header) => (
            <TableCell key={header} align='right' className={classes.headerCell}>
              {statsHeaders[header]}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {playerObjects?.map((player) => (
          <TableRow
            key={player.id}
            className={classes.hoverRow}
            onClick={() => handleRowClick(player.id)}
            style={{ cursor: 'pointer' }}
          >
            <TableCell align='left'>{player.fullName}</TableCell>
            {headers?.map((header) => (
              <TableCell key={header} align='right' className={classes.tableCell}>
                {showValue === 'average' ? player.avgStats[header] : player.totalStats[header]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

PlayersTable.propTypes = {
  players: PropTypes.arrayOf(
    PropTypes.shape({
      playerId: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      gamesPlayed: PropTypes.number.isRequired,
      gamesStarted: PropTypes.number,
      gameStats: PropTypes.shape({
        points: PropTypes.number.isRequired,
        avgPoints: PropTypes.number.isRequired,
        twoPointer: PropTypes.number.isRequired,
        avgTwoPoints: PropTypes.number.isRequired,
        totalTwoPointsAttempts: PropTypes.number.isRequired,
        threePointer: PropTypes.number.isRequired,
        avgThreePoints: PropTypes.number.isRequired,
        totalThreePointsAttempts: PropTypes.number.isRequired,
        freeThrows: PropTypes.number.isRequired,
        avgFreeThrows: PropTypes.number.isRequired,
        totalFreeThrowsAttempts: PropTypes.number.isRequired,
        offensiveRebounds: PropTypes.number.isRequired,
        avgOffensiveRebounds: PropTypes.number.isRequired,
        defensiveRebounds: PropTypes.number.isRequired,
        avgDefensiveRebounds: PropTypes.number.isRequired,
        totalRebounds: PropTypes.number.isRequired,
        avgRebounds: PropTypes.number.isRequired,
        assists: PropTypes.number.isRequired,
        avgAssists: PropTypes.number.isRequired,
        steals: PropTypes.number.isRequired,
        avgSteals: PropTypes.number.isRequired,
        turnOvers: PropTypes.number.isRequired,
        avgTurnOvers: PropTypes.number.isRequired,
        blocksAgainst: PropTypes.number.isRequired,
        avgBlocksAgainst: PropTypes.number.isRequired,
        foulsCommited: PropTypes.number.isRequired,
        avgFoulsCommited: PropTypes.number.isRequired,
        foulsDrawn: PropTypes.number.isRequired,
        avgFoulsDrawn: PropTypes.number.isRequired,
        pir: PropTypes.number.isRequired,
        avgPIR: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  showValue: PropTypes.string.isRequired,
}

export default PlayersTable
