import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const addCourt = async (data) => {
  const url = BASE_URL + '/courts'

  const response = await axios.post(url, JSON.stringify(data), {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default addCourt
