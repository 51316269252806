import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getPracticePlan = async (data) => {
  const { practicePlanId } = data
  const url = `${BASE_URL}/practice-plans/${practicePlanId}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getPracticePlan
