import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getLatestRevisions = async ({trainingModuleId}) => {
  const url = `${BASE_URL}/training-module-revisions/latest/${trainingModuleId}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getLatestRevisions
