import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslate } from 'react-admin'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import orgUsersPageStyles from '../OrgUsers/OrgUsersPage/orgUsersPage.styles'
import MyTable from '../../components/DynamicTable/MyTable/MyTable'
import UserField from '../../components/DynamicTable/Fields/UserField/UserField'
import TypographyField from '../../components/DynamicTable/Fields/TypographyField/TypographyField'
import AcceptRejectField from '../../components/DynamicTable/Fields/AcceptRejectField/AcceptRejectField'
import QueryKeys from '../../utils/constants/queryKeys'
import invitationAccept from '../../Apis/invitations/invitationAccept'
import invitationReject from '../../Apis/invitations/invitationReject'
import getUserInvitations from '../../Apis/invitations/getUserInvitations'
import DialogWithCloseIcon from '../../components/DialogWithCloseIcon/DialogWithCloseIcon'
import ButtonWithLoader from '../../components/ui/ButtonWithLoader/ButtonWithLoader'
import MySnackbar from '../../components/MySnackbar/MySnackbar'
import localStorageIds from '../../utils/constants/localStorageIds'
import CardWrapperMoreIcon from '../../components/layout/CardWrapperMoreIcon/CardWrapperMoreIcon'
import invitationsListStyles from './invitationsList.styles'
import { setLocalStorageValue } from '../../utils/helpers/localStorageHelperMethods'

const InvitationsList = () => {
  const [invitations, setInvitations] = useState([])
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [deleteInvitationModal, setDeleteInvitationModal] = useState({
    isOpen: false,
    senderName: '',
    id: '',
    orgName: '',
  })
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const useStyles = makeStyles(invitationsListStyles)
  const classes = useStyles()

  const updateAfterInvitationAccept = (token) => {
    setLocalStorageValue([{ id: localStorageIds.TOKEN, value: token }])

    queryClient.invalidateQueries(QueryKeys.GET_INVITATIONS).then(() => {})
    queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME).then(() => {
      queryClient.invalidateQueries(QueryKeys.GET_ORGS).then(() => {
        queryClient.invalidateQueries(QueryKeys.GET_ORGS_AFTER_LOGIN).then(() => {
          queryClient.invalidateQueries(QueryKeys.GET_TEAMS_AFTER_LOGIN).then(() => {})
        })
      })
    })

    setIsSnackbarOpen(true)
  }

  const memberInvitations = useQuery(QueryKeys.GET_INVITATIONS, () => getUserInvitations(), {
    onSuccess: (response) => {
      setInvitations(
        response.data?.map((inv) => {
          return { ...inv, name: inv.senderFirstName.concat(' ', inv.senderLastName) }
        }),
      )
    },
  })

  const acceptInvitation = useMutation(
    QueryKeys.INVITATION_ACCEPT,
    (data) => invitationAccept(data),
    {
      onSuccess: (res) => {
        updateAfterInvitationAccept(res?.data?.token)
      },
    },
  )

  const rejectInvitation = useMutation(
    QueryKeys.INVITATION_REJECT,
    (data) => invitationReject(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_INVITATIONS).then(() => {})
        setIsSnackbarOpen(true)
      },
    },
  )

  const onAcceptClick = (e, row) => {
    e.preventDefault()
    e.stopPropagation()

    acceptInvitation.mutate({ invitationId: row.id })
  }
  const onRejectClick = (e, row) => {
    e.preventDefault()
    e.stopPropagation()
    setDeleteInvitationModal({
      isOpen: true,
      senderName: row.name,
      id: row.id,
      orgName: row.orgName,
    })
  }

  return (
    <>
      <DialogWithCloseIcon
        open={deleteInvitationModal.isOpen}
        onClose={() => setDeleteInvitationModal({ ...deleteInvitationModal, isOpen: false })}
        dialogTitle={
          <Typography style={{ width: '95%' }} className={classes.cardTitle}>
            {`${translate('ra.text.deleteInvitationConfirmation')} ${
              deleteInvitationModal.senderName
            } to join ${deleteInvitationModal.orgName}?
            `}
          </Typography>
        }
        dialogWidth='40%'
      >
        <DialogActions>
          <Button
            variant='text'
            classes={{ root: classes.cancelButton }}
            onClick={() => setDeleteInvitationModal({ ...deleteInvitationModal, isOpen: false })}
          >
            {translate('ra.buttons.cancel')}
          </Button>
          <ButtonWithLoader
            label={translate('ra.buttons.reject')}
            className={classes.deleteButton}
            onClick={() => {
              rejectInvitation.mutate({ invitationId: deleteInvitationModal.id })
              setDeleteInvitationModal({ ...deleteInvitationModal, isOpen: false })
            }}
            loading={rejectInvitation.isLoading}
          />
        </DialogActions>
      </DialogWithCloseIcon>

      <CardWrapperMoreIcon>
        <Card className={classes.card}>
          <CardHeader
            title={
              <Typography className={classes.cardTitle} variant='h5'>
                {translate('ra.text.invitations')}
              </Typography>
            }
            subheader={
              <Typography className={classes.cardSubtitle}>
                {translate('ra.text.invitationsList')}
              </Typography>
            }
          />
          <CardContent>
            <Divider variant='middle' className={classes.divider} />
            {memberInvitations.isLoading ? (
              <LoaderDots style={orgUsersPageStyles.loaderIcon} />
            ) : (
              <MyTable rows={invitations} hasHeader hasDivider size='small' boldHeader>
                <TypographyField title='Sender' key='name' colorChoice='black' />
                <UserField title='Organisation' key='orgName' logoName='orgLogo' />
                <TypographyField title='Role' key='role' colorChoice='black' />
                <AcceptRejectField
                  title=''
                  onAcceptClick={onAcceptClick}
                  onRejectClick={onRejectClick}
                  columnAlignment='center'
                />
              </MyTable>
            )}
          </CardContent>
        </Card>
      </CardWrapperMoreIcon>
      <MySnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        isSuccess={acceptInvitation.isSuccess && !rejectInvitation.isSuccess}
        successMessage={translate('ra.successMessages.invitationAccepted')}
        errorMessage={translate('ra.errorMessages.errorMessages')}
      />
    </>
  )
}

export default InvitationsList
