import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const updateUserOrgRoles = async (data) => {
  const { orgId, requestBody } = data
  const url = `${BASE_URL}/clubs/${orgId}/update-user`

  return axios.patch(url, requestBody, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default updateUserOrgRoles
