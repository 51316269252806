import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const patchSubscriptionDetails = (data) => {
  const { formData, orgId } = data
  const url = `${BASE_URL}/billing/details?clubId=${orgId}`

  return axios.patch(url, formData, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default patchSubscriptionDetails
