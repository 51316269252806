import axios from 'axios'
import { BASE_URL } from '../../../utils/constants/url'
import generalHeader from '../../../utils/constants/generalHeader'

const updatePlaybook = (data) => {
  const { values, playBookId } = data
  const url = `${BASE_URL}/play-books/${playBookId}`

  return axios.patch(url, values, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default updatePlaybook
