import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'

const StatsGrid = ({ stats, showValue = 'average' }) => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const classes = useStyles()

  const displayedStats = {
    points: 'Points',
    assists: 'Assists',
    rebounds: 'Rebounds',
    steals: 'Steals',
    blocks: 'Blocks',
    pir: 'PIR',
    totalRebounds: 'Rebounds',
    blocksAgainst: 'Blocks',
  }

  const displayedAvgStats = {
    points: 'avgPoints',
    assists: 'avgAssists',
    rebounds: 'avgRebounds',
    steals: 'avgSteals',
    blocks: 'avgBlocks',
    pir: 'avgPIR',
    totalRebounds: 'avgRebounds',
    blocksAgainst: 'avgBlocksAgainst',
  }

  const selectedStats = showValue === 'average' ? displayedAvgStats : displayedStats

  return (
    <Grid container spacing={2}>
      {Object.entries(stats).map(
        ([key, value]) =>
          displayedStats[key] && (
            <Grid item xs={6} sm={4} md={3} lg={2} className={classes.statItem} key={key}>
              <Card className={classes.statCard}>
                <CardContent className={classes.cardContent}>
                  <div className={classes.statCircleArcTop} />
                  <Typography className={classes.statValue}>
                    {showValue === 'average' ? stats[selectedStats[key]] : value}
                  </Typography>
                  <Typography variant='body6' className={classes.statLabel}>
                    {displayedStats[key]}
                  </Typography>
                  <div className={classes.statCircleArcBottom} />
                </CardContent>
              </Card>
            </Grid>
          ),
      )}
    </Grid>
  )
}

StatsGrid.propTypes = {
  stats: PropTypes.shape({
    teamId: PropTypes.string.isRequired,
    gamesPlayed: PropTypes.number.isRequired,
    wins: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired,
    avgPoints: PropTypes.number.isRequired,
    rebounds: PropTypes.number.isRequired,
    avgRebounds: PropTypes.number.isRequired,
    assists: PropTypes.number.isRequired,
    avgAssists: PropTypes.number.isRequired,
    steals: PropTypes.number.isRequired,
    avgSteals: PropTypes.number.isRequired,
    blocks: PropTypes.number.isRequired,
    avgBlocks: PropTypes.number.isRequired,
    fouls: PropTypes.number.isRequired,
    avgFouls: PropTypes.number.isRequired,
    turnovers: PropTypes.number.isRequired,
    avgTurnovers: PropTypes.number.isRequired,
    freeThrows: PropTypes.number.isRequired,
    missedFreeThrows: PropTypes.number.isRequired,
    avgMissedFreeThrows: PropTypes.number.isRequired,
    twoPointer: PropTypes.number.isRequired,
    missed2Pointers: PropTypes.number.isRequired,
    avgMissed2Pointers: PropTypes.number.isRequired,
    threePointer: PropTypes.number.isRequired,
    missed3Pointers: PropTypes.number.isRequired,
    avgMissed3Pointers: PropTypes.number.isRequired,
    pir: PropTypes.number.isRequired,
    avgPIR: PropTypes.number.isRequired,
  }).isRequired,
  showValue: PropTypes.string,
}

export default StatsGrid
