const playerStatsTableStyles = () => ({
  typo: {
    fontSize: '14px',
    textAlign: 'center',
  },
  boldTypo: {
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'center',
  },
  marginTypo: {
    marginTop: '20px',
  },
  tableCell: {
    fontSize: '14px',
    textAlign: 'center',
  },
  tableHeader: {
    backgroundColor: '#E0726E',
  },
})

export default playerStatsTableStyles
