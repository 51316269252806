import { React } from 'react'
import { makeStyles } from '@material-ui/core'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'
import StatsGrid from './StatsGrid'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import getPlayer from '../../Apis/player/getPlayer'
import QueryKeys from '../../utils/constants/queryKeys'
import { useQuery } from 'react-query'
import getPlayerStats from '../../Apis/stats/getPlayerStats'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import PlayerStatsTable from './PlayerStatsTable'
import { useLocation } from 'react-router-dom'
import PlayerInfoCard from './PlayerInfoCard'

const PlayerStatisticsPage = () => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const location = useLocation()
  const playerId = location?.pathname?.split('/')?.[2]

  const classes = useStyles()

  const { data: playerStats, isFetching: isPlayerStatsFetching } = useQuery(
    [QueryKeys.GET_PLAYER_STATS],
    () => getPlayerStats({ playerId: playerId }),
  )

  const { data: playerData, isFetching: isPlayerDataFetching } = useQuery(
    [QueryKeys.GET_PLAYER],
    () => getPlayer({ playerId: playerId }),
  )

  return (
    <ContainerPadding paddingValue='1%' className={classes.statsContainer}>
      {isPlayerDataFetching ? (
        <ContainerPadding paddingValue='45%'>
          <LoaderDots className={classes.loaderDots} />
        </ContainerPadding>
      ) : (
        <ContainerPadding paddingValue='1%'>
          <PlayerInfoCard playerInfo={playerData} />
        </ContainerPadding>
      )}

      {isPlayerStatsFetching ? (
        <ContainerPadding paddingValue='45%'>
          <LoaderDots className={classes.loaderDots} />
        </ContainerPadding>
      ) : (
        <ContainerPadding paddingValue='1%'>
          <StatsGrid stats={playerStats?.data.playerStats.gameStats} />
          <PlayerStatsTable playerData={playerStats?.data} />
        </ContainerPadding>
      )}
    </ContainerPadding>
  )
}

export default PlayerStatisticsPage
