import { makeStyles, TextField } from '@material-ui/core'
import { useState } from 'react'
import { useMutation } from 'react-query'
import decodeJwt from 'jwt-decode'
import TEXT_FIELD_STYLES from '../../utils/constants/CSS/textFieldStyles'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'
import impersonateUser from '../../Apis/user/impersonateUser'
import {
  removeLocalStorageValue,
  setLocalStorageValue,
} from '../../utils/helpers/localStorageHelperMethods'
import localStorageIds from '../../utils/constants/localStorageIds'
import useGetLocalStorage from '../../hooks/useGetLocalStorage'

const ImpersonationField = (props) => {
  const { sideBarOpen } = props
  const [impersonationValue, setImpersonationValue] = useState('')
  const localStorageValue = useGetLocalStorage()
  const { [localStorageIds.IS_SUPER_ADMIN]: isSuperAdminLocal } = localStorageValue || {}
  const useStyles = makeStyles(TEXT_FIELD_STYLES)
  const classes = useStyles()

  const impersonateUserMutation = useMutation((data) => impersonateUser(data), {
    onSuccess: (res) => {
      const { data } = res
      const { orgScopes, orgRoles, Subscriptions, isSuperAdmin } = decodeJwt(data?.token)

      // Set localStorage values
      setLocalStorageValue([
        { id: localStorageIds.TOKEN, value: data?.token },
        { id: localStorageIds.SCOPES, value: orgScopes },
        { id: localStorageIds.USER_ORG_ROLES, value: orgRoles },
        { id: localStorageIds.SUBSCRIPTIONS, value: Subscriptions },
        { id: localStorageIds.INVITATIONS_SNACK_OPEN, value: false },
        {
          id: localStorageIds.IS_SUPER_ADMIN,
          value: JSON.parse(isSuperAdmin?.toLowerCase()),
        },
      ])

      removeLocalStorageValue([
        localStorageIds.USER_INITIAL_UNCONFIRMED_EMAIL,
        localStorageIds.SELECTED_ORG,
        localStorageIds.SELECTED_TEAM,
      ])

      window.location = window.location.pathname
    },
  })

  const onImpersonateClick = (userId, impersonate) => {
    impersonate.mutate({ userId })
  }

  if (!isSuperAdminLocal || !sideBarOpen || typeof isSuperAdminLocal !== 'boolean')
    return <div></div>

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 5,
        marginBottom: 10,
      }}
    >
      <TextField
        label='User Id'
        type='input'
        variant='filled'
        onChange={(e) => setImpersonationValue(e.target.value)}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.root,
            input: classes.input,
          },
        }}
        InputLabelProps={{
          classes: {
            shrink: classes.shrink,
            formControl: classes.formControl,
            focused: classes.focused,
            error: classes.error,
          },
        }}
      />
      <ButtonWithLoader
        label='IMPERSONATE'
        onClick={() => onImpersonateClick(impersonationValue, impersonateUserMutation)}
        loading={impersonateUserMutation.isLoading}
      />
    </div>
  )
}

export default ImpersonationField
