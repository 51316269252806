import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const resumeLivestream = async (broadcastId) => {
  const url = `${BASE_URL}/livestreams/${broadcastId}/resume`

  return axios.post(
    url,
    {},
    {
      headers: generalHeader(localStorage.getItem('token')),
    },
  )
}

export default resumeLivestream
