import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getTeamStats = async ({ teamId, games }) => {
  const url = `${BASE_URL}/stats/team/${teamId}`

  return axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
    params: {
      lastNGames: games,
    },
  })
}

export default getTeamStats
