import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const activateCoupon = (data) => {
  const { couponCode, orgId } = data
  const url = `${BASE_URL}/billing/coupons`

  const payload = { couponCode, orgId }

  return axios.post(url, payload, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default activateCoupon
