import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const deleteOrg = async (data) => {
  const { orgId } = data
  const url = `${BASE_URL}/clubs/${orgId}`

  return axios.delete(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default deleteOrg
