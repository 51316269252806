import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const addTeam = async (data) => {
  const { orgId, ...restData } = data
  const url = `${BASE_URL}/teams?clubId=${orgId}`

  return axios.post(url, restData, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default addTeam
