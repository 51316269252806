import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getUserByEmail = async (data) => {
  const url = `${BASE_URL}/users/get-user-by-email`

  return axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
    params: data,
  })
}

export default getUserByEmail
