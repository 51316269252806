import COLORS from '../../utils/constants/colors'

const gamesListStyles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '16px',
    marginBottom: '16px',
  },
  game: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.hoverGrey,
    },
  },
  gameDate: {
    display: 'inline-block',
    marginRight: '8px',
  },
  notes: {
    fontStyle: 'italic',
    color: COLORS.gray,
  },
  finalScoreBtn: {
    backgroundColor: COLORS.orange,
    color: COLORS.white,
    padding: '8px 16px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
  },
})

export default gamesListStyles
