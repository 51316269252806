import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getPracticePlayers = async ({ practicePlayersId }) => {
  const url = `${BASE_URL}/practice-players/${practicePlayersId}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getPracticePlayers
