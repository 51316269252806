import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getLivestreamTypes = async (data) => {
  const { eventTypeCode } = data
  const url = `${BASE_URL}/events/broadcast-types?EventTypeCode=${eventTypeCode}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getLivestreamTypes
