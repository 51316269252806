const localStorageIds = {
  TOKEN: 'token',
  SCOPES: 'scopes',
  SUBSCRIPTIONS: 'subscriptions',
  USER_INITIAL_UNCONFIRMED_EMAIL: 'userInitialUnconfirmedEmail',
  USER_ORG_ROLES: 'userOrgRoles',
  SELECTED_ORG: 'selectedOrg',
  SELECTED_TEAM: 'selectedTeam',
  INVITATIONS_SNACK_OPEN: 'invitationsSnackOpen',
  IS_SUPER_ADMIN: 'isSuperAdmin',
}

export default localStorageIds
