import React, { useEffect, useState } from 'react'
import { makeStyles, Container, Typography, TableContainer, Paper } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'
import COLORS from '../../utils/constants/colors'
import useQueryParams from '../../hooks/useQueryParams'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import QueryKeys from '../../utils/constants/queryKeys'
import getGameStats from '../../Apis/stats/getGameStats'
import getSingleTeam from '../../Apis/team/getSingleTeam'
import GameStatsInfo from './GameStatsInfo'
import PerQuarterTable from './PerQuarterTable'
import PlayersPerGameTable from './PlayerPerGameTable'
import MyToggleButton from '../../components/MyToggleButton/MyToggleButton'
import NoStatsAvailablePage from './NoStatsAvalaiblePage'

const GameStatisticsPage = () => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const classes = useStyles()
  const [toggleValue, setToggleValue] = useState()
  const [players, setPlayers] = useState()
  const translate = useTranslate()
  const queryParams = useQueryParams()

  const { data: gameStats, isFetching: isGameStatsFetching } = useQuery(
    [QueryKeys.GET_GAME_STATS],
    () => getGameStats({ gameId: queryParams?.eventId }),
    {
      enabled: Boolean(queryParams?.eventId),
    },
  )

  // Query to fetch team 1 details
  const { data: team1, isFetching: isTeam1Fetching } = useQuery(
    ['team1', gameStats], // The query key: ['team1', gameStats] ensures the query is unique and dependent on the gameStats data
    () => getSingleTeam(gameStats?.data[0]?.teamId),
    {
      enabled: Boolean(gameStats?.data[0]?.teamId && gameStats && queryParams?.eventId),
    },
  )

  // Query to fetch team 2 details
  const { data: team2, isFetching: isTeam2Fetching } = useQuery(
    ['team2', gameStats], // The query key: ['team2', gameStats] ensures the query is unique and dependent on the gameStats data
    () => getSingleTeam(gameStats?.data[1]?.teamId),
    {
      enabled: Boolean(gameStats?.data[1]?.teamId && gameStats && queryParams?.eventId),
    },
  )

  useEffect(() => {
    if (toggleValue === 'team1Players') {
      setPlayers(gameStats?.data[0]?.teamPlayerStats)
    } else {
      setPlayers(gameStats?.data[1]?.teamPlayerStats)
    }
  }, [isGameStatsFetching, toggleValue])

  useEffect(() => {
    if (gameStats && gameStats.data) {
      setToggleValue('team1Players')
    }
  }, [isGameStatsFetching, isTeam1Fetching, isTeam2Fetching])

  return (
    <Container>
      {isGameStatsFetching || isTeam1Fetching || isTeam2Fetching ? (
        <ContainerPadding paddingValue='45%'>
          <LoaderDots
            style={{
              fill: COLORS.orange,
              width: 70,
              height: 70,
            }}
          />
        </ContainerPadding>
      ) : (
        <ContainerPadding paddingValue='1%' className={classes.statsContainer}>
          {gameStats?.data.length === 0 ? (
            <NoStatsAvailablePage />
          ) : (
            <>
              <GameStatsInfo gameStats={gameStats} homeTeam={team1} awayTeam={team2} />
              <Typography variant='h6' gutterBottom style={{ marginTop: '50px' }}>
                {translate('ra.text.perQuarter')}
              </Typography>
              <PerQuarterTable teams={gameStats.data} />

              <Typography variant='h6' gutterBottom style={{ marginTop: '50px' }}>
                {translate('ra.text.endOfQuarter')}
              </Typography>
              <PerQuarterTable teams={gameStats.data} sum />

              <Typography
                variant='h6'
                gutterBottom
                style={{ marginTop: '50px', display: 'flex', justifyContent: 'space-between' }}
              >
                <span>{translate('ra.text.selectedPlayers')}</span>
                <MyToggleButton
                  left={{
                    value: 'team1Players',
                    label: team1?.name,
                  }}
                  right={{
                    value: 'team2Players',
                    label: team2?.name,
                  }}
                  setToggleValue={setToggleValue}
                  toggleValue={toggleValue}
                />
              </Typography>
              <TableContainer component={Paper}>
                <PlayersPerGameTable players={players} />
              </TableContainer>
            </>
          )}
        </ContainerPadding>
      )}
    </Container>
  )
}

export default GameStatisticsPage
