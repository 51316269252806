import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { DialogActions, Divider, makeStyles, Typography } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import SetRolesMemberFields from './SetRolesMemberFields/SetRolesMemberFields'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import setRolesModalStyles from './setRolesModal.styles'
import localStorageIds from '../../../utils/constants/localStorageIds'
import TextButton from '../../../components/ui/TextButton/TextButton'
import isObjectEmptyHelper from '../../../utils/helpers/isObjectEmptyHelper'
import localStorageHelper from '../../../utils/helpers/localStorageHelper'

const SetRolesModal = (props) => {
  const { invitations, setInvitations, onClose, stepForward } = props
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const { localStorageValue: selectedOrg } = localStorageHelper(localStorageIds.SELECTED_ORG)
  const { scopes: orgScopes } = selectedOrg
  const translate = useTranslate()
  const useStyles = makeStyles(setRolesModalStyles)
  const classes = useStyles()

  const handleCloseSnackbar = () => {
    setIsSnackbarOpen(false)
  }

  const onSubmit = (values) => {
    const invitationValues = values.invitations.map((item) => {
      const teamRoles = {}

      if (!item.isAdmin) {
        item.roles.forEach((teamRole) => {
          // Checks if new team added without selecting any option. Could become differently
          if (Object.values(teamRole).filter((value) => value !== null).length > 1)
            teamRoles[teamRole.Team.id] = teamRole.Role.replace(/\s/g, '')
        })
      }

      return {
        ...item,
        scopes: orgScopes.length > 1 ? item.scopes : [orgScopes[0]],
        roles: !isObjectEmptyHelper(teamRoles) ? teamRoles : undefined,
      }
    })

    setInvitations(invitationValues)
    stepForward()
  }

  const validate = (values) => {
    const errorsCreate = {}
    if (values.invitations.some((inv) => inv.orgScopes?.length === 0)) {
      errorsCreate.teams = translate('ra.validationErrors.requiredField')
    }

    return errorsCreate
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          invitations: invitations.map((inv) => {
            return {
              receiverEmail: inv.receiverEmail,
              isOwner: false,
              isAdmin: false,
              scopes: [],
              roles: [{}],
            }
          }),
        }}
        validate={validate}
        onSubmit={(values) => onSubmit(values)}
      >
        {() => (
          <Form style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <ContainerPadding paddingValue='2%' style={{ position: 'relative', overflow: 'auto' }}>
              {invitations.map((inv, index) => (
                <div key={inv.name}>
                  <Divider />
                  <Typography name={`invitations[${index}].name`} className={classes.userName}>
                    {inv.name}
                  </Typography>
                  <SetRolesMemberFields orgScopeOptions={orgScopes} index={index} />
                </div>
              ))}
            </ContainerPadding>
            <div className={classes.dialogActions}>
              <Divider />
              <DialogActions>
                <TextButton
                  label={translate('ra.buttons.skip')}
                  onClick={() => {
                    onClose()
                  }}
                />
                <ButtonWithLoader label={translate('ra.buttons.next')} />
              </DialogActions>
            </div>
          </Form>
        )}
      </Formik>
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert variant='filled' severity='error'>
          {translate('ra.errorMessages.invitationSendError')}
        </Alert>
      </Snackbar>
    </>
  )
}

SetRolesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  invitations: PropTypes.array.isRequired,
  setEmails: PropTypes.func.isRequired,
  setInvitations: PropTypes.func,
  stepForward: PropTypes.func,
}
export default SetRolesModal
