import LIVESTREAM_TYPE_OPTIONS from './livestreamTypeOptions'

export const RECORD = 'record'
export const BROADCAST = 'broadcast'
export const DEFAULT_TYPE_GAME = LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_FULL
export const DEFAULT_TYPE_PRACTICE = LIVESTREAM_TYPE_OPTIONS.LIVESTREAM_LITE

export const LIVESTREAM_TYPE_OPTIONS_MAPPER_GAME = {
  [RECORD]: (saveType) => saveType,
  [BROADCAST]: (type) => type ?? DEFAULT_TYPE_GAME,
}

export const LIVESTREAM_TYPE_OPTIONS_MAPPER_PRACTICE = {
  [RECORD]: (saveType) => saveType,
  [BROADCAST]: (type) => type ?? DEFAULT_TYPE_PRACTICE,
}
