import React, { useState } from 'react'
import GamesList from './GamesList'
import StatsNavBar from './StatsNavBar'

const StatsNavigationPage = ({ children }) => {
  const [currentTab, setCurrentTab] = useState('stats')

  return (
    <div>
      <StatsNavBar currentTab={currentTab} setCurrentTab={setCurrentTab} />
      {currentTab === 'games' ? <GamesList /> : children}
    </div>
  )
}

export default StatsNavigationPage
