import React, { useState } from 'react'
import '../../../../utils/constants/CSS/eventForms.css'
import { Field, Form, Formik } from 'formik'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import useEventFormsValidations from '../../../../hooks/useEventFormsValidations'
import parseEventFormSubmit from '../../../../utils/helpers/eventHelpers/parseEventFormSubmit'
import FormContainer from '../../../layout/FormContainer/FormContainer'
import MyDateField from '../../fields/MyDateField'
import { isFieldDisabled } from '../../../../utils/helpers/isFieldDisabled'
import MyTimeField from '../../fields/MyTimeField'
import MyGroupIcon from '../../../../assets/icons/myGroupIcon'
import MyAutocomplete from '../../fields/MyAutocomplete'
import MySelectField from '../../fields/MySelectField/MySelectField'
import MyLocationOnIcon from '../../../../assets/icons/myLocationOnIcon'
import MyMultilineTextField from '../../fields/MyMultilineTextField'
import IsUserScopesAuthorized from '../../../IsUserScopesAuthorized/IsUserScopesAuthorized'
import EventFormLivestreamFields from '../../../EventFormLivestreamFields/EventFormLivestreamFields'
import GetFormDirtyValue from '../../../getFormDirtyValue/getFormDirtyValue'
import AutoChangeFakeEvent from '../../AutoChangeFakeEvent'
import onEventFieldChange from '../../../../utils/helpers/eventHelpers/onEventFieldChange'
import { USER_SCOPES } from '../../../../utils/constants/userScopes'
import EventFormCourtLogos from '../../../EventFormCourtLogos/EventFormCourtLogos'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../../../utils/constants/gameEventFormBroadcastFields'
import courtType from '../../../../types/courtType'
import mutationType from '../../../../types/mutationType'
import teamType from '../../../../types/teamType'
import eventVariantType from '../../../../types/events/eventVariantType'
import calendarDateClickType from '../../../../types/calendarDateClickType'
import eventType from '../../../../types/events/eventType'
import eventRawType from '../../../../types/events/eventRawType'
import parseGameEventFormSubmit from '../../../../utils/helpers/eventHelpers/parseGameEventFormSubmit'
import ConditionalRender from '../../../ConditionalRender/ConditionalRender'
import isCourtLivestreamEnabled from '../../../../utils/helpers/isCourtLivestreamEnabled'
import {
  setEventEndTime,
  setEventStartTime,
} from '../../../../utils/helpers/eventHelpers/setEventTime'
import {
  BROADCAST,
  LIVESTREAM_TYPE_OPTIONS_MAPPER_GAME,
  RECORD,
} from '../../../../utils/constants/livestreamTypeOptionsMapper'
import AddCourtModal from '../../../ui/AddCourtModal/AddCourtModal'
import AddButton from '../../../ui/AddButton/AddButton'
import GameSubmitConfirmationModal from '../../../GameSubmitConfirmationModal/GameSubmitConfirmationModal'
import isUserOrgRolesValid from '../../../../utils/helpers/isUserOrgRolesValid'
import localStorageIds from '../../../../utils/constants/localStorageIds'
import USER_ORG_ROLES from '../../../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../../../utils/constants/userOrgMembersRoles'
import ids from '../../../../utils/constants/introIds'
import EventTeamsFields from './EventTeamsFields/EventTeamsFields'
import MyCourtIcon from '../../../../assets/icons/MyCourtIcon'
import useGetLocalStorage from '../../../../hooks/useGetLocalStorage'
import ORGANISATION_TYPES from '../../../../utils/constants/OrganisationTypes'
import SelectRenderValue from '../../../SelectRenderValue/SelectRenderValue'

const GameEventForm = (props) => {
  const {
    clickedEventId,
    createEvent,
    updateEvent,
    event,
    selectedEventType,
    setFormDirty,
    currentTeam,
    teams,
    dateClick,
    eventsRaw,
    setEvents,
    courts,
  } = props
  const {
    place,
    court,
    notes,
    broadcast,
    media,
    ytBroadcastDetails,
    homeTeamName,
    awayTeamName,
    homeTeamLogo,
    awayTeamLogo,
    competitionName,
    competitionLogo,
  } = event || {}
  const {
    id,
    youtubeStreamKey,
    youtubeStreamKey2,
    state,
    courtTopLeftLogo,
    courtTopRightLogo,
    courtBottomLeftLogo,
    courtBottomRightLogo,
    courtCenterLogo,
    streamerSaveUrl,
    isCommentaryOn,
    type,
    saveType,
  } = broadcast || {}

  const { broadcastId, thumbnailUrl, broadcastTitle, visibility } = ytBroadcastDetails || {}
  const [isCourtModalOpen, setIsCourtModalOpen] = useState(false)
  const [isUploadingModalOpen, setIsUploadingModalOpen] = useState(false)
  const [mediaIsUploading, setMediaIsUploading] = useState(false)
  const useOrgYoutube = broadcastId || !youtubeStreamKey
  const localStorageValue = useGetLocalStorage()
  const { [localStorageIds.SELECTED_ORG]: selectedOrg } = localStorageValue || {}
  const { type: orgType, youTubeChannelName } = selectedOrg
  const hasSetYoutube = Boolean(youTubeChannelName)
  const translate = useTranslate()
  const { createLivestreamEventValidations, editLivestreamEventValidations } =
    useEventFormsValidations('game', id, hasSetYoutube, useOrgYoutube)
  const { onFromDateFieldChange, onStartTimeFieldChange, onEndTimeFieldChange } = onEventFieldChange
  const { OWNER, ADMIN } = USER_ORG_ROLES
  const { TEAM_MANAGER } = USER_ORG_MEMBERS_ROLES
  const allowOrgYoutube =
    hasSetYoutube &&
    isUserOrgRolesValid(
      [OWNER, ADMIN, TEAM_MANAGER],
      teams?.map((team) => team.id),
    )

  const onGameSubmit = (data) => {
    if (event) {
      updateEvent.mutate({
        formData: parseEventFormSubmit(parseGameEventFormSubmit(data, courts, mediaIsUploading)),
        eventId: clickedEventId,
        eventType: selectedEventType,
      })
    } else {
      createEvent.mutate({
        formData: parseEventFormSubmit(parseGameEventFormSubmit(data, courts, mediaIsUploading)),
        eventType: selectedEventType,
      })
    }
  }

  return (
    // TODO: Add all values names in a constant
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validate={clickedEventId ? editLivestreamEventValidations : createLivestreamEventValidations}
      initialValues={{
        fromDate: setEventStartTime(event, dateClick),
        startTime: setEventStartTime(event, dateClick),
        endTime: setEventEndTime(event, dateClick),
        teams: event?.teams ?? currentTeam,
        place: event ? place : '',
        courtId: court?.id ?? '',
        notes: event ? notes : '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST]: !!youtubeStreamKey || !!youtubeStreamKey2,
        [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]: !!streamerSaveUrl,
        [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_COMMENTARY_ON]: isCommentaryOn || false,
        [GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE]: streamerSaveUrl
          ? LIVESTREAM_TYPE_OPTIONS_MAPPER_GAME[RECORD](saveType)
          : LIVESTREAM_TYPE_OPTIONS_MAPPER_GAME[BROADCAST](type),
        [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY]: !useOrgYoutube
          ? youtubeStreamKey
          : null,
        [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_ADDITIONAL_YOUTUBE_STREAM_KEY_ADDED]:
          !!youtubeStreamKey2,
        [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY_2]: youtubeStreamKey2 ?? null,
        [GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]:
          !useOrgYoutube || !allowOrgYoutube
            ? null
            : {
                broadcastId: broadcastId ?? null,
                [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_THUMBNAIL]: thumbnailUrl ?? null,
                [GAME_EVENT_FORM_BROADCAST_FIELDS.BROADCAST_TITLE]: broadcastTitle ?? '',
                [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_VISIBILITY]: visibility ?? '',
              },
        [GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME_NAME]: homeTeamName ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY_NAME]: awayTeamName ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_HOME_LOGO]: homeTeamLogo ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.TEAM_AWAY_LOGO]: awayTeamLogo ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_COMPETITION_LOGO]: competitionLogo ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_COMPETITION_NAME]: competitionName ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_LEFT_LOGO]: courtTopLeftLogo ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_TOP_RIGHT_LOGO]: courtTopRightLogo ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_LEFT_LOGO]: courtBottomLeftLogo ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_BOTTOM_RIGHT_LOGO]: courtBottomRightLogo ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.COURT_CENTER_LOGO]: courtCenterLogo ?? '',
        [GAME_EVENT_FORM_BROADCAST_FIELDS.UPLOADED_MEDIAS]: media
          ? media.reduce((acc, obj) => {
              acc[obj.fileName] = obj.id
              return acc
            }, {})
          : {},
      }}
      onSubmit={(data) => {
        if (mediaIsUploading) {
          setIsUploadingModalOpen(true)
          return
        }

        onGameSubmit(data)
      }}
    >
      {({ values, setFieldValue }) => (
        <Form id={ids.createEvent.form}>
          <FormContainer>
            <MyDateField
              id={ids.createEvent.date}
              name='fromDate'
              label={translate('ra.formFields.date')}
              required
              disabled={isFieldDisabled(state)}
              onChange={onFromDateFieldChange}
            />

            <div id={ids.createEvent.time} className='div-flex-simple div-flex-responsive-1100'>
              <MyTimeField
                name='startTime'
                label={translate('ra.formFields.startTime')}
                required
                disabled={isFieldDisabled(state)}
                onChange={onStartTimeFieldChange}
              />
              <MyTimeField
                name='endTime'
                label={translate('ra.formFields.endTime')}
                required
                disabled={isFieldDisabled(state, 'endTime')}
                onChange={onEndTimeFieldChange}
              />
            </div>

            <ConditionalRender renderValue={orgType === ORGANISATION_TYPES.ORG_CLUB}>
              <div id={ids.createEvent.team} className='div-flex'>
                <MyGroupIcon className='icon' />
                {currentTeam ? (
                  event ? (
                    <span>{event.teams?.map((team) => team.name).join(', ')}</span>
                  ) : (
                    <span>{currentTeam.name}</span>
                  )
                ) : (
                  <Field
                    name='teams'
                    label='Select a team'
                    style={{ width: '100%' }}
                    required
                    options={teams}
                    component={MyAutocomplete}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => {
                      return (
                        <SelectRenderValue
                          item={option}
                          chosenOption={values.teams?.[0] || ''}
                          logoArgName='teamLogo'
                          labelBlack
                        />
                      )
                    }}
                  />
                )}
              </div>
            </ConditionalRender>

            {orgType === ORGANISATION_TYPES.ORG_CLUB && (
              <div className='div-flex'>
                <MyLocationOnIcon className='icon' />
                <MySelectField
                  name='place'
                  label={translate('ra.formFields.place')}
                  options={[
                    translate('ra.formFields.home'),
                    translate('ra.formFields.away'),
                    translate('ra.formFields.neutral'),
                  ]}
                  required
                  disabled={isFieldDisabled(state)}
                />
              </div>
            )}

            <div id={ids.createEvent.court} className='div-flex'>
              <MyCourtIcon />
              <MySelectField
                name='courtId'
                label={translate('ra.formFields.courtName')}
                options={courts}
                emptyText={translate('ra.text.emptyCourts')}
                fullWidth
                disabled={isFieldDisabled(state)}
                addOptionsComponent={
                  <AddButton
                    onClick={() => setIsCourtModalOpen(true)}
                    label={translate('ra.action.add_court')}
                    noHoverEffect={courts?.length}
                    style={{
                      paddingLeft: !courts?.length ? 8 : 4,
                      marginRight: !courts?.length ? 10 : 0,
                    }}
                  />
                }
              />

              <AddCourtModal
                isCourtModalOpen={isCourtModalOpen}
                setIsCourtModalOpen={setIsCourtModalOpen}
                setValue={(newCourtValue) => setFieldValue('courtId', newCourtValue)}
              />
            </div>

            <EventTeamsFields state={state} orgType={orgType} teams={teams} />

            <MyMultilineTextField
              name='notes'
              label={translate('ra.formFields.notes')}
              multiline
              minRows={5}
              fullWidth
              disabled={isFieldDisabled(state)}
            />

            <IsUserScopesAuthorized acceptedScopes={[USER_SCOPES.LIVESTREAM]}>
              <ConditionalRender renderValue={isCourtLivestreamEnabled(courts, values?.courtId)}>
                <EventFormLivestreamFields
                  state={state}
                  supportsCommentary={
                    courts?.find((item) => item.id === values.courtId)?.supportsCommentary || false
                  }
                  allowOrgYoutube={allowOrgYoutube}
                  broadcastId={broadcastId ?? null}
                  emptyThumbnailNotAllowed={!!thumbnailUrl}
                  selectedEventType={selectedEventType}
                />
                <EventFormCourtLogos
                  state={state}
                  setMediaIsUploading={setMediaIsUploading}
                  selectedEventType={selectedEventType}
                />
              </ConditionalRender>
            </IsUserScopesAuthorized>
          </FormContainer>

          <GetFormDirtyValue setFormDirty={setFormDirty} />
          <AutoChangeFakeEvent
            clickedEventId={clickedEventId}
            selectedEventType={selectedEventType}
            eventsRaw={eventsRaw}
            setEvents={setEvents}
          />
          <GameSubmitConfirmationModal
            isOpen={isUploadingModalOpen}
            onCancelClick={() => {
              setIsUploadingModalOpen(false)
            }}
            onCreateClick={() => {
              setIsUploadingModalOpen(false)
              onGameSubmit(values)
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

GameEventForm.propTypes = {
  clickedEventId: PropTypes.string,
  createEvent: mutationType,
  updateEvent: mutationType,
  event: eventType,
  selectedEventType: eventVariantType,
  setFormDirty: PropTypes.func,
  currentTeam: teamType,
  teams: PropTypes.arrayOf(teamType),
  dateClick: calendarDateClickType,
  eventsRaw: PropTypes.arrayOf(eventRawType),
  setEvents: PropTypes.func,
  courts: PropTypes.arrayOf(courtType),
}

export default GameEventForm
