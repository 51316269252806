import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getLivestream = async (data) => {
  const { broadcastId } = data
  const url = `${BASE_URL}/livestreams/${broadcastId}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getLivestream
