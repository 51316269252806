import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const addOrg = async (data) => {
  const { name, countryId, city, logo, type, scopes } = data
  const url = `${BASE_URL}/clubs`

  const requestBody = {
    name,
    countryId,
    city,
    logo,
    type,
    scopes,
  }

  return axios.post(url, requestBody, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default addOrg
