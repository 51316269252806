import React from 'react'
import InviteUsersModal from './InviteUsersModal/InviteUsersModal'
import SetRolesModal from './SetRolesModal/SetRolesModal'
import InviteUsersConfirmation from './InviteUsersConfirmation'

const InviteUsersWizardContents = (props) => {
  const {
    me,
    emails,
    setEmails,
    invitations,
    setInvitations,
    orgUsers,
    orgId,
    subscriptionDetails,
    inviteUsersMutation,
    onClose,
    activeStep,
    stepForward,
  } = props

  const wizardStepContentMapper = {
    0: (
      <InviteUsersModal
        emails={emails}
        setEmails={setEmails}
        me={me}
        orgUsers={orgUsers}
        stepForward={stepForward}
      />
    ),

    1: (
      <SetRolesModal
        invitations={emails.map((email) => {
          return {
            name: email.existedUser ? email.name : email.email,
            receiverEmail: email.email,
          }
        })}
        setInvitations={setInvitations}
        onClose={onClose}
        stepForward={stepForward}
      />
    ),

    2: (
      <InviteUsersConfirmation
        invitations={invitations}
        userId={me.data.id}
        orgId={orgId}
        subscriptionDetails={subscriptionDetails}
        inviteUsersMutation={inviteUsersMutation}
        onClose={onClose}
      />
    ),
  }

  return wizardStepContentMapper[activeStep]
}

export default InviteUsersWizardContents
