import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  Divider,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useTranslate, useRedirect } from 'react-admin'
import decodeJwt from 'jwt-decode'
import TabPanel from '../../../components/TabPanel/TabPanel'
import subscriptionPageStyles from './subscriptionPage.styles'
import MainSubscriptionTab from '../MainSubscriptionTab/MainSubscriptionTab'
import SubscriptionStatusTab from '../SubscriptionStatusTab/SubscriptionStatusTab'
import PaymentsTab from '../PaymentsTab/PaymentsTab'
import QueryKeys from '../../../utils/constants/queryKeys'
import COLORS from '../../../utils/constants/colors'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import useUrlSearchParams from '../../../hooks/useUrlSearchParams'
import verifyTransaction from '../../../Apis/subscriptions/verifyTransaction'
import localStorageIds from '../../../utils/constants/localStorageIds'
import { setLocalStorageValue } from '../../../utils/helpers/localStorageHelperMethods'
import useHistoryPush from '../../../hooks/useHistoryPush'
import SubscriptionForm from '../../../components/form/forms/SubscriptionForm/SubscriptionForm'
import getSubscriptionDetails from '../../../Apis/subscriptions/getSubscriptionDetails'
import SUBSCRIPTION_STATES from '../../../utils/constants/subscriptionStates'
import orgIsInSubscriptionState from '../../../utils/helpers/orgIsInSubscriptionState'
import DialogWithCloseIcon from '../../../components/DialogWithCloseIcon/DialogWithCloseIcon'
import SubscriptionWizardContents from '../SubscriptionWizard/SubscriptionWizardContents'
import activateAddon from '../../../Apis/subscriptions/activateAddon'
import deactivateAddon from '../../../Apis/subscriptions/deactivateAddon'
import activateSubscription from '../../../Apis/subscriptions/activateSubscription'
import ConnectionErrorPage from '../../ErrorInConnection/ConnectionErrorPage'

const SubscriptionPage = () => {
  const [tab, setTab] = useState(0)
  const [selectedAddon, setSelectedAddon] = useState()
  const [selectedMultipleAddons, setSelectedMultipleAddons] = useState([])
  const [paymentSuccess, setPaymentSuccess] = useState()
  const [updateSubscriptionModalOpen, setUpdateSubscriptionModalOpen] = useState(false)
  const [usersToInvite, setUsersToInvite] = useState({})
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const externalRedirect = useRedirect()
  const { org: orgId, t } = useUrlSearchParams(['org', 't']) || {}
  const { redirect, pathname } = useHistoryPush()
  const useStyles = makeStyles(subscriptionPageStyles)
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1100))

  const {
    data: subscriptionDetails,
    isLoading,
    error,
  } = useQuery([QueryKeys.GET_SUBSCRIPTION_DETAILS], () => getSubscriptionDetails({ orgId }), {
    enabled: Boolean(orgId),
  })

  useEffect(() => {
    if (subscriptionDetails && subscriptionDetails.addons) {
      const enabledAddons = subscriptionDetails.addons.filter((addon) => addon.enabled)
      setSelectedMultipleAddons(enabledAddons)
    }
  }, [subscriptionDetails])

  const activateOrgAddon = useMutation((data) => activateAddon(data))
  const deactivateOrgAddon = useMutation((data) => deactivateAddon(data))
  const activateOrgSubscription = useMutation((data) => activateSubscription(data))

  const verifyTheTransaction = useQuery(
    [QueryKeys.VIVA_WEBHOOK],
    () =>
      verifyTransaction({
        orgId,
        vivaPaymentOrderInitialTransactionId: t,
      }),
    {
      enabled: Boolean(t && orgId),
      onSuccess: (res) => {
        const { jwtToken, isTransactionSuccessful } = res?.data || {}
        const { Subscriptions } = decodeJwt(jwtToken)
        setLocalStorageValue([
          { id: localStorageIds.TOKEN, value: jwtToken },
          {
            id: localStorageIds.SUBSCRIPTIONS,
            value: Subscriptions,
          },
        ])
        setPaymentSuccess(isTransactionSuccessful)

        // This is only to remove unnecessary url query params
        redirect(pathname, null, ['t', 's', 'lang', 'eventId', 'eci'])
      },
    },
  )

  const handleUpdateSubscriptionAddons = () => {
    if (
      !subscriptionDetails ||
      !subscriptionDetails.cardFourLastDigits ||
      orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE, SUBSCRIPTION_STATES.TRIAL])
    ) {
      setTab(1)
    } else {
      setUpdateSubscriptionModalOpen(true)
    }
  }

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      className: classes.indicator,
    }
  }

  const steps = ['Add-on Activation', 'Select Users', 'Confirm your payment details']

  const handleActivateAddon = (addon) => {
    setSelectedAddon(addon)
    setUpdateSubscriptionModalOpen(true)
  }

  const handleDeactivateAddon = (addon) => {
    const data = {
      type: addon.id,
      orgId,
    }
    deactivateOrgAddon.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_SUBSCRIPTION_DETAILS)
        queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME)
      },
    })
  }

  const handleCloseUpdateSubscriptionModal = () => {
    setUsersToInvite({})
    setUpdateSubscriptionModalOpen(false)
  }

  const handleAddonConfirmation = () => {
    const userIds = usersToInvite.map((user) => user.id)

    const data = {
      userIds,
      type: selectedAddon.id,
      orgId,
    }
    setUpdateSubscriptionModalOpen(false)
    setUsersToInvite({})
    activateOrgAddon.mutate(data, {
      onSuccess: (res) => {
        const { initialPayment } = res?.data || {}
        const { vivaPaymentOrderCode } = initialPayment || {}
        if (vivaPaymentOrderCode) {
          externalRedirect(`${process.env.REACT_APP_VIVA_URL}${vivaPaymentOrderCode}`)
        } else {
          queryClient.invalidateQueries(QueryKeys.GET_SUBSCRIPTION_DETAILS)
          queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME)
          setPaymentSuccess(true)
        }
      },
    })
  }

  const handleActivateSubscription = () => {
    setSelectedAddon(null)
    const data = {
      orgId,
    }
    activateOrgSubscription.mutate(data, {
      onSuccess: (res) => {
        const { vivaPaymentOrderCode } = res?.data || {}
        if (vivaPaymentOrderCode) {
          externalRedirect(`${process.env.REACT_APP_VIVA_URL}${vivaPaymentOrderCode}`)
        } else {
          queryClient.invalidateQueries(QueryKeys.GET_SUBSCRIPTION_DETAILS)
          queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME)
          setTab(0)
          setPaymentSuccess(true)
        }
      },
    })
  }

  return (
    <Card className={classes.root}>
      <AppBar position='static' color='transparent' elevation={0} className={classes.appbar}>
        <Tabs
          value={tab}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: COLORS.subscriptionBlue } }}
          textColor='inherit'
        >
          <Tab
            label={translate('ra.page.subscription')}
            {...a11yProps(0)}
            style={{
              color: tab === 0 ? COLORS.subscriptionBlue : COLORS.lightGrey,
            }}
            disabled={!!error}
          />
          <Tab
            label={translate('ra.page.billingDetails')}
            {...a11yProps(1)}
            style={{
              color: tab === 1 ? COLORS.subscriptionBlue : COLORS.lightGrey,
            }}
            disabled={!!error}
          />
          <Tab
            label={translate('ra.page.payments')}
            {...a11yProps(2)}
            style={{
              color: tab === 2 ? COLORS.subscriptionBlue : COLORS.lightGrey,
            }}
            disabled={!!error}
          />
        </Tabs>
      </AppBar>
      <Divider light orientation='horizontal' />
      <TabPanel value={tab} index={0}>
        {verifyTheTransaction.isLoading || isLoading ? (
          <ContainerPadding paddingValue='45%'>
            <LoaderDots
              style={{
                fill: COLORS.orange,
                width: 70,
                height: 70,
              }}
            />
          </ContainerPadding>
        ) : paymentSuccess !== undefined ? (
          <SubscriptionStatusTab
            selectedAddon={selectedAddon}
            selectedMultipleAddons={selectedMultipleAddons}
            paymentSuccess={paymentSuccess}
            setPaymentSuccess={setPaymentSuccess}
            isCardVerification={verifyTheTransaction?.data?.data?.isCardVerification || true}
          />
        ) : !error ? (
          <MainSubscriptionTab
            setPaymentSuccess={setPaymentSuccess}
            subscriptionDetails={subscriptionDetails}
            handleActivateAddon={handleActivateAddon}
            handleDeactivateAddon={handleDeactivateAddon}
            handleUpdateSubscriptionAddons={handleUpdateSubscriptionAddons}
            handleActivateSubscription={handleActivateSubscription}
            setTab={setTab}
          />
        ) : (
          <ConnectionErrorPage />
        )}
      </TabPanel>
      <TabPanel value={tab} index={1} disabled={!!error}>
        <div style={{ width: '60%', margin: 'auto' }}>
          <SubscriptionForm
            setPaymentSuccess={setPaymentSuccess}
            showCard
            setTab={setTab}
            handleActivateSubscription={handleActivateSubscription}
          />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={2} disabled={true}>
        <PaymentsTab />
      </TabPanel>

      <DialogWithCloseIcon
        open={updateSubscriptionModalOpen}
        onClose={handleCloseUpdateSubscriptionModal}
        dialogWidth={isSmallScreen ? '90%' : '85%'}
        maxDialogWidth={isSmallScreen ? '1000px' : '900px'}
        maxDialogHeight={isSmallScreen ? '700px' : '600px'}
        wizardSteps={steps}
      >
        <SubscriptionWizardContents
          billingAddon={selectedAddon}
          usersToInvite={usersToInvite}
          subscriptionDetails={subscriptionDetails}
          setUsersToInvite={setUsersToInvite}
          setPaymentSuccess={setPaymentSuccess}
          finish={handleAddonConfirmation}
        />
      </DialogWithCloseIcon>
    </Card>
  )
}

export default SubscriptionPage
