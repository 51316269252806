import axios from 'axios'
import { BASE_URL } from '../../../utils/constants/url'
import generalHeader from '../../../utils/constants/generalHeader'

const getPlaybooks = async (data) => {
  const { teamId } = data

  const queryString = `?teamId=${teamId}`
  const url = `${BASE_URL}/play-books${queryString}`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getPlaybooks
