import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'
import mapPracticePlayers from '../../utils/helpers/practicePlayersMapper/mapPracticePlayers'

const updatePracticePlayers = async (data) => {
  const { practicePlayersId, eventId, players, teams } = data

  const mappedPracticePlayers = mapPracticePlayers(eventId, players, teams)
  const url = `${BASE_URL}/practice-players/${practicePlayersId}`

  return axios.patch(url, mappedPracticePlayers, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default updatePracticePlayers
