import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'
import mapPracticePlayers from '../../utils/helpers/practicePlayersMapper/mapPracticePlayers'

const addPracticePlayers = async (data) => {
  const { eventId, players, teams } = data

  const mappedPracticePlayers = mapPracticePlayers(eventId, players, teams)
  const url = `${BASE_URL}/practice-players`

  return axios.post(url, mappedPracticePlayers, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default addPracticePlayers
