import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const updateOrg = async (data) => {
  const { name, city, countryId, orgId, logo } = data
  const url = `${BASE_URL}/clubs/${orgId}`

  return axios.patch(
    url,
    {
      name,
      countryId,
      city,
      logo,
    },
    {
      headers: generalHeader(localStorage.getItem('token')),
    },
  )
}

export default updateOrg
