import React from 'react'
import PropTypes from 'prop-types'
import SubscriptionSeatsNotice from '../../components/SubscriptionSeatsNotice/SubscriptionSeatsNotice'
import mutationType from '../../types/mutationType'

const InviteUsersConfirmation = (props) => {
  const { invitations, userId, orgId, subscriptionDetails, inviteUsersMutation } = props

  return (
    <div>
      <SubscriptionSeatsNotice
        invitations={invitations}
        subscriptionDetails={subscriptionDetails}
        userId={userId}
        orgId={orgId}
        inviteUsersMutation={inviteUsersMutation}
      />
    </div>
  )
}

InviteUsersConfirmation.propTypes = {
  onClose: PropTypes.func.isRequired,
  invitations: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  orgId: PropTypes.string,
  subscriptionDetails: PropTypes.object.isRequired,
  inviteUsersMutation: mutationType,
}

export default InviteUsersConfirmation
