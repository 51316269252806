import COLORS from '../../utils/constants/colors'

const teamStatisticsPageStyles = () => ({
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f0f0f0',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderRadius: '8px',
  },
  teamCard: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#ffffff',
  },
  teamLogo: {
    width: '120px',
    height: '120px',
    marginRight: '20px',
  },
  teamCardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  teamName: {
    fontWeight: 'bold',
    color: COLORS.primary,
  },
  teamVictories: {
    marginLeft: '12px',
    fontSize: '14px',
    color: COLORS.primary,
  },
  teamVictoriesNum: {
    marginLeft: '12px',
    fontSize: '14px',
    color: COLORS.primary,
    paddingLeft: '12px',
  },
  tabMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  tabButton: {
    padding: '10px 20px',
    border: '1px solid #6200ea',
    backgroundColor: '#ffffff',
    color: '#6200ea',
    cursor: 'pointer',
  },
  activeTab: {
    backgroundColor: '#6200ea',
    color: '#ffffff',
  },
  statCard: {
    overflow: 'visible',
    minHeight: '240px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statItem: {
    textAlign: 'center',
  },
  statCircleArcTop: {
    width: '85px',
    height: '40px',
    borderTop: '4px solid #E0726E',
    borderRadius: '50%',
    marginBottom: '-30px',
  },
  statCircleArcBottom: {
    width: '85px',
    height: '40px',
    borderBottom: '4px solid #E0726E',
    borderRadius: '50%',
    marginTop: '-30px',
  },
  statValue: {
    fontSize: '38px',
    fontWeight: 'bold',
    color: '#000000',
  },
  statLabel: {
    fontSize: '15px',
    color: COLORS.darkGrey,
    marginTop: '2px',
  },
  strong: {
    paddingBottom: '10px',
  },
  dialogContentText: {
    fontSize: '1.2rem',
    color: COLORS.darkGrey,
  },
  dialogHeader: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  card: {
    display: 'flex',
    marginBottom: '10px',
  },
  media: {
    width: 150,
    height: 150,
  },
  content: {
    flex: '1 0 auto',
  },
  infoBox: {
    marginTop: '1px',
  },
  hoverRow: {
    transition: 'background-color 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.hoverGrey,
    },
  },
  loaderDots: {
    fill: COLORS.orange,
    width: 70,
    height: 70,
  },
  tableCell: {
    whiteSpace: 'nowrap',
    fontSize: '12px',
    textAlign: 'center',
  },
  headerCell: {
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
})

export default teamStatisticsPageStyles
