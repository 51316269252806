import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const activateSubscription = async (data) => {
  const url = `${BASE_URL}/billing/activate-subscription`

  return axios.post(url, data.orgId, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default activateSubscription
