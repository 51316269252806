import { BASE_URL } from "../../utils/constants/url";
import generalHeader from "../../utils/constants/generalHeader";

export const updateCourt = async (data) => {
  const { courtId, ...rest } = data;
  const url = BASE_URL + `/courts/${courtId}`;

  const response = await fetch(url, {
    method: "PATCH",
    headers: generalHeader(localStorage.getItem('token')),
    body: JSON.stringify({ ...rest })
  });

  return response.json();
}
