import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const updateTactic = async ({ childId }) => {
  const url = `${BASE_URL}/tactics/${childId}`

  return axios.delete(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default updateTactic
