import React from 'react'
import {
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  makeStyles,
  CardMedia,
} from '@material-ui/core'
import gameStatsInfoStyles from './gameStatsInfo.styles'
import DefaultOrgIcon from '../../assets/icons/DefaultOrgIcon'

const GameStatsInfo = ({ gameStats, homeTeam, awayTeam }) => {
  const useStyles = makeStyles(gameStatsInfoStyles)
  const classes = useStyles()

  if (!gameStats || !homeTeam || !awayTeam) {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant='h5'>Error: Missing required data.</Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Container className={classes.logoContainer}>
          {homeTeam.teamLogo ? (
            <CardMedia className={classes.cardMedia} image={homeTeam.teamLogo} />
          ) : (
            <DefaultOrgIcon className={classes.cardMedia} />
          )}
          <Typography variant='h5'>{homeTeam.name}</Typography>
        </Container>
        <Box className={classes.scoreBox}>
          <Typography variant='h5'>Final Score</Typography>
          <Typography variant='h5'>
            {gameStats.data[0].score} : {gameStats.data[1].score}
          </Typography>
        </Box>
        <Container className={classes.logoContainer}>
          {awayTeam.teamLogo ? (
            <CardMedia className={classes.cardMedia} image={awayTeam.teamLogo} />
          ) : (
            <DefaultOrgIcon className={classes.cardMedia} />
          )}
          <Typography variant='h5'>{awayTeam.name}</Typography>
        </Container>
      </CardContent>
    </Card>
  )
}

export default GameStatsInfo
