import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const deleteUserFromOrg = async (data) => {
  const { orgId, userId } = data
  const url = `${BASE_URL}/clubs/${orgId}/remove-user?userId=${userId}`

  return axios.delete(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default deleteUserFromOrg
