import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const disableUserAccount = async ({ password }) => {
  const url = `${BASE_URL}/users/me/`

  const response = await axios.delete(url, {
    data: {
      Password: password,
    },
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response.data
}

export default disableUserAccount
