import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const addPracticePlan = async (data) => {
  const { values } = data
  const { eventId, description, components } = values
  const url = `${BASE_URL}/practice-plans`

  return axios.post(
    url,
    {
      eventId,
      description,
      practicePlanComponentForCreationDtos: components,
    },
    {
      headers: generalHeader(localStorage.getItem('token')),
    },
  )
}

export default addPracticePlan
