import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getOrgPendingInvitations = async (props) => {
  const { orgId } = props
  const url = `${BASE_URL}/Invitations/${orgId}/pending-invitations`

  const response = await axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })

  return response?.data
}

export default getOrgPendingInvitations
