import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const youtubeUnlink = async (data) => {
  const { orgId } = data
  const url = `${BASE_URL}/YouTube/unlink?clubId=${orgId}`

  return axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default youtubeUnlink
