import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const updateEvent = async (data) => {
  const { formData, eventId, eventType } = data
  const url = `${BASE_URL}/events/${eventType.code}/${eventId}`

  return axios.patch(url, formData, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default updateEvent
