import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import useHistoryPush from '../../hooks/useHistoryPush'
import playerPerGameTableStyles from './playerPerGameTable.styles'

const PlayersPerGameTable = ({ players }) => {
  const useStyles = makeStyles(playerPerGameTableStyles)
  const classes = useStyles()
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  if (!players || players.length === 0) {
    return null
  }

  const playerObjects = players?.map((player) => {
    return {
      id: player.playerId,
      fullName: player.fullName,
      minutes: player.minutes,
      points: player.gameStats.points,
      twoPointer: `${player.gameStats.twoPointer}/${player.gameStats.totalTwoPointsAttempts}`,
      avgTwoPoints: `${((player.gameStats.twoPointer / player.gameStats.totalTwoPointsAttempts) * 100).toFixed(1)}%`,
      threePointer: `${player.gameStats.threePointer}/${player.gameStats.totalThreePointsAttempts}`,
      avgThreePoints: `${((player.gameStats.threePointer / player.gameStats.totalThreePointsAttempts) * 100).toFixed(1)}%`,
      freeThrows: `${player.gameStats.freeThrows}/${player.gameStats.totalFreeThrowsAttempts}`,
      avgFreeThrows: `${((player.gameStats.freeThrows / player.gameStats.totalFreeThrowsAttempts) * 100).toFixed(1)}%`,
      offensiveRebounds: player.gameStats.offensiveRebounds,
      defensiveRebounds: player.gameStats.defensiveRebounds,
      totalRebounds: player.gameStats.totalRebounds,
      assists: player.gameStats.assists,
      steals: player.gameStats.steals,
      turnOvers: player.gameStats.turnOvers,
      blocksAgainst: player.gameStats.blocksAgainst,
      foulsCommited: player.gameStats.foulsCommited,
      foulsDrawn: player.gameStats.foulsDrawn,
      pir: player.gameStats.pir,
    }
  })

  const handleRowClick = (playerId) => {
    redirect(`/player-statistics/${playerId}`)
  }

  return (
    <Table>
      <TableHead>
        <TableRow className={classes.tableHeader}>
          <TableCell align='center'>
            <div className={classes.containerDiv}>
              <span className={classes.span}>&nbsp;</span>
              <div className={classes.div}>
                <span>{translate('ra.text.players')}</span>
              </div>
            </div>
          </TableCell>
          <TableCell colSpan={2} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>&nbsp;</span>
              <div className={classes.div}>
                <span>MIN</span>
                <span>PTS</span>
              </div>
            </div>
          </TableCell>
          <TableCell colSpan={2} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>2FG</span>
              <div className={classes.div}>
                <span>M/A</span>
                <span>%</span>
              </div>
            </div>
          </TableCell>

          <TableCell colSpan={2} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>3FG</span>
              <div className={classes.div}>
                <span>M/A</span>
                <span>%</span>
              </div>
            </div>
          </TableCell>

          <TableCell colSpan={2} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>FT</span>
              <div className={classes.div}>
                <span>M/A</span>
                <span>%</span>
              </div>
            </div>
          </TableCell>
          <TableCell colSpan={3} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>REBOUNDS</span>
              <div className={classes.div}>
                <span>OR</span>
                <span>DR</span>
                <span>TR</span>
              </div>
            </div>
          </TableCell>

          <TableCell colSpan={3} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>&nbsp;</span>
              <div className={classes.div}>
                <span>AST</span>
                <span>STL</span>
                <span>TO</span>
              </div>
            </div>
          </TableCell>

          <TableCell className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>&nbsp;</span>
              <div className={classes.div}>
                <span>BLKA</span>
              </div>
            </div>
          </TableCell>
          <TableCell colSpan={2} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>FOULS</span>
              <div className={classes.div}>
                <span>FC</span>
                <span>FD</span>
              </div>
            </div>
          </TableCell>
          <TableCell className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>&nbsp;</span>
              <div className={classes.div}>
                <span>PIR</span>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {playerObjects.map((player) => (
          <TableRow
            key={player.id}
            onClick={() => handleRowClick(player.id)}
            className={classes.hoverRow}
          >
            <TableCell>{player.fullName}</TableCell>
            <TableCell className={classes.tableCell}>{player.minutes}</TableCell>
            <TableCell className={classes.tableCell}>{player.points}</TableCell>
            <TableCell className={classes.tableCell}>{player.twoPointer}</TableCell>
            <TableCell className={classes.tableCell}>{player.avgTwoPoints}</TableCell>
            <TableCell className={classes.tableCell}>{player.threePointer}</TableCell>
            <TableCell className={classes.tableCell}>{player.avgThreePoints}</TableCell>
            <TableCell className={classes.tableCell}>{player.freeThrows}</TableCell>
            <TableCell className={classes.tableCell}>{player.avgFreeThrows}</TableCell>
            <TableCell className={classes.tableCell}>{player.offensiveRebounds}</TableCell>
            <TableCell className={classes.tableCell}>{player.defensiveRebounds}</TableCell>
            <TableCell className={classes.tableCell}>{player.totalRebounds}</TableCell>
            <TableCell className={classes.tableCell}>{player.assists}</TableCell>
            <TableCell className={classes.tableCell}>{player.steals}</TableCell>
            <TableCell className={classes.tableCell}>{player.turnOvers}</TableCell>
            <TableCell className={classes.tableCell}>{player.blocksAgainst}</TableCell>
            <TableCell className={classes.tableCell}>{player.foulsCommited}</TableCell>
            <TableCell className={classes.tableCell}>{player.foulsDrawn}</TableCell>
            <TableCell className={classes.tableCell}>{player.pir}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default PlayersPerGameTable
