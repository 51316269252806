import { Drawer, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { useTranslate } from 'react-admin'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { v4 } from 'uuid'
import CloseDrawer from '../../../components/CloseDrawer/CloseDrawer'
import usersDrawerStyles from './usersDrawer.styles'
import UserAvatar from '../../../components/user/UserAvatar/UserAvatar'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import TextButton from '../../../components/ui/TextButton/TextButton'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import MyAutocomplete from '../../../components/form/fields/MyAutocomplete'
import MyAdjustableFields from '../../../components/form/fields/MyAdjustableFields/MyAdjustableFields'
import QueryKeys from '../../../utils/constants/queryKeys'
import getOrgRoles from '../../../Apis/organisation/getOrgRoles'
import useQueryParams from '../../../hooks/useQueryParams'
import deleteUserFromOrg from '../../../Apis/organisation/deleteUserFromOrg'
import DeleteModal from '../../../components/DeleteModal/DeleteModal'
import deleteInvitation from '../../../Apis/invitations/deleteInvitation'
import updateInvitation from '../../../Apis/invitations/updateInvitation'
import updateUserOrgRoles from '../../../Apis/organisation/updateUserOrgRoles'
import reformatRoleNames from '../../../utils/helpers/reformatRoleNames'
import localStorageIds from '../../../utils/constants/localStorageIds'
import USER_ORG_ROLES from '../../../utils/constants/userOrgRoles'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import localStorageHelper from '../../../utils/helpers/localStorageHelper'
import { USER_SCOPES } from '../../../utils/constants/userScopes'
import orgIsInSubscriptionState from '../../../utils/helpers/orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../../../utils/constants/subscriptionStates'

const UsersDrawer = (props) => {
  const { open, onClose, userDetails, teams } = props
  const { id, name, email, logoUrl, isInvitation, scopes, orgRole, roles } = userDetails || {}
  const queryParams = useQueryParams()
  const { org: orgId } = queryParams
  const { localStorageValue: selectedOrg } = localStorageHelper(localStorageIds.SELECTED_ORG)
  const { scopes: orgScopes } = selectedOrg
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAdjustableFields, setShowAdjustableFields] = useState(orgRole === 'Member')
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const useStyles = makeStyles(usersDrawerStyles)
  const classes = useStyles()

  const { data: orgRoles } = useQuery([QueryKeys.GET_ORG_ROLES], () => getOrgRoles())

  const deleteOrgUserMutation = useMutation(
    QueryKeys.DELETE_USER_FROM_ORG,
    (data) => deleteUserFromOrg(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_ORG_PENDING_INVITATIONS).then(() => {})
      },
    },
  )
  const deleteOrgUserInvitationMutation = useMutation(
    QueryKeys.DELETE_INVITATION,
    (data) => deleteInvitation(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_ORG_PENDING_INVITATIONS).then(() => {})
      },
    },
  )

  const updateInvitationMutation = useMutation(
    QueryKeys.UPDATE_INVITATION,
    (data) => updateInvitation(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_ORG_PENDING_INVITATIONS).then(() => {})
      },
    },
  )
  const updateUserRolesMutation = useMutation(
    QueryKeys.UPDATE_USER_ORG_ROLES,
    (data) => updateUserOrgRoles(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_ORG_PENDING_INVITATIONS).then(() => {})
      },
    },
  )

  const onDeleteUser = () => {
    if (isInvitation) {
      deleteOrgUserInvitationMutation.mutate({ invitationId: id, orgId })
    } else {
      deleteOrgUserMutation.mutate({ orgId, userId: id })
    }

    onClose()
  }

  const onRoleChange = (value, setRoleFieldValue) => {
    setRoleFieldValue('orgRole', value)
    setShowAdjustableFields(value === 'Member')
    value !== 'Member' && setRoleFieldValue('roles', [{ key: v4(), team: '', role: '' }])
  }

  const onSubmit = (values, formikHelpers) => {
    const { resetForm } = formikHelpers
    const isMember = showAdjustableFields
    const requestBody = {
      roles: isMember ? {} : null,
      userId: !isInvitation ? id : null,
      scopes: values.scopes,
    }

    if (isMember) {
      values.roles.forEach((teamRole) => {
        requestBody.roles[teamRole.team.id] = teamRole.role.replace(/\s/g, '')
      })
    }

    if (isInvitation) {
      updateInvitationMutation.mutate({ invitationId: id, orgId, requestBody })
    } else {
      updateUserRolesMutation.mutate({ orgId, requestBody })
    }

    resetForm({
      values,
    })
  }

  return (
    <Drawer open={open} anchor='right' classes={{ paper: classes.drawerPaper }} disableEnforceFocus>
      <CloseDrawer onClick={() => onClose()} />
      <Formik
        initialValues={{ orgRole, scopes, roles }}
        onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
      >
        {({ dirty, values, setFieldValue }) => (
          <Form>
            <ContainerPadding paddingValue='6%'>
              <div className={classes.header}>
                <UserAvatar
                  style={{ width: 130, height: 130, paddingLeft: '0px', fill: '#bdbdbd' }}
                  userAvatar={logoUrl}
                />
                <Grid container justifyContent='flex-end' alignItems='center' style={{ gap: 15 }}>
                  {deleteOrgUserMutation.isLoading || deleteOrgUserInvitationMutation.isLoading ? (
                    <LoaderDots />
                  ) : (
                    <TextButton
                      onClick={() => setShowDeleteModal(true)}
                      label={translate('ra.buttons.delete')}
                      className={classes.deleteButton}
                    />
                  )}
                  <ButtonWithLoader
                    label={translate('ra.buttons.update')}
                    disabled={!dirty}
                    loading={
                      updateInvitationMutation.isLoading || updateUserRolesMutation.isLoading
                    }
                  />
                </Grid>
              </div>
              <div className={classes.infoHeader}>
                <Typography className={classes.typoBold}>{name}</Typography>
                <Typography className={classes.typoGrey}>{email}</Typography>
              </div>
              <div className={classes.formContainer}>
                {orgScopes && orgScopes?.length > 1 && (
                  <Field
                    className={classes.formItem}
                    name='scopes'
                    label={translate('ra.formFields.userPermissions')}
                    multiple
                    required
                    autoSelect={false}
                    component={MyAutocomplete}
                    options={
                      // If org is on Inactive or grace subscription state, owner cannot invite coach members. In future probably will get controlled by be
                      orgIsInSubscriptionState([
                        SUBSCRIPTION_STATES.INACTIVE,
                        SUBSCRIPTION_STATES.GRACE,
                      ])
                        ? orgScopes.filter(
                            (option) =>
                              !values.scopes?.includes(option) && option !== USER_SCOPES.COACH,
                          )
                        : orgScopes.filter((option) => !values.scopes?.includes(option))
                    }
                    fullWidth
                    value={values.scopes}
                  />
                )}
              </div>
              <div className={classes.formContainer}>
                <Field
                  name='orgRole'
                  label={translate('ra.formFields.OrgRole')}
                  variant='filled'
                  required
                  autoSelect={false}
                  options={Object.values(USER_ORG_ROLES).slice(2)}
                  component={MyAutocomplete}
                  onChange={(e, value) => onRoleChange(value, setFieldValue)}
                  value={values.orgRole}
                />
              </div>
              {showAdjustableFields && (
                <MyAdjustableFields
                  parentField='roles'
                  fieldsNumber={2}
                  labels={[translate('ra.formFields.team'), translate('ra.formFields.role')]}
                  keys={['team', 'role']}
                  options={[
                    teams?.filter(
                      (team) =>
                        !values.roles
                          .map((role) => (role?.team ? role?.team.id : ''))
                          .includes(team.id),
                    ) || [],
                    orgRoles ? reformatRoleNames(orgRoles.slice(2)) : [],
                  ]}
                  isFirstRequired={values?.orgRole === 'Member'}
                />
              )}
            </ContainerPadding>
            <DeleteModal
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              handleDelete={() => onDeleteUser()}
              title={translate('ra.text.deleteOrgUser')}
              loading={deleteOrgUserMutation.isLoading || deleteOrgUserInvitationMutation.isLoading}
            />
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}

export default UsersDrawer
