import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const googleAuthorizationCallback = async (data) => {
  const { code, orgId } = data
  const url = `${BASE_URL}/YouTube/google-authorization-callback?code=${code}&clubId=${orgId}`

  return axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default googleAuthorizationCallback
