import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const inviteUsers = async (props) => {
  const { data } = props
  const url = `${BASE_URL}/Invitations`

  return axios.post(url, data, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default inviteUsers
