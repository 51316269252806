import COLORS from '../../utils/constants/colors'

const playerPerGameTableStyles = () => ({
  tableHeader: { backgroundColor: '#E0726E' },
  tableCellCenter: {
    textAlign: 'center',
  },
  tableCellBorder: {
    borderLeft: '1px solid black',
    textAlign: 'center',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    fontSize: '12px',
    textAlign: 'center',
  },
  hoverRow: {
    transition: 'background-color 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.hoverGrey,
    },
  },
  span: {
    textAlign: 'center',
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerDiv: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export default playerPerGameTableStyles
