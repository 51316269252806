import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'

const getUserInvitations = async () => {
  const url = `${BASE_URL}/Invitations/user-pending-invitations`

  return axios.get(url, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default getUserInvitations
